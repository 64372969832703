import { Modal, Checkbox, Row, Col, Spin, Radio } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import {
 TAutomationTypes,
 useAssignBulkNewsLetterMutation,
 useAssignToActionPlanBulkMutation,
} from 'features/automation'
import { useAssignAlertBulkMutation } from 'features/alert'
import {
 TLeadCustomSelectAll,
 TSelectAllB,
 TSelectAllP,
 useAssignGroupToLeadBulkMutation,
} from 'features/leads'
import { TListTagStageSource, useGetGroupsQuery } from 'features/settings'
import TSSFormModal from 'features/settings/components/TSSFormModal'
import { FC, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/functions'
import InfiniteScroll from 'react-infinite-scroller'
import useFetchPage2 from 'hooks/useFetchPage2'
import useAuth from 'hooks/useAuth'
import { useAddRealtorSourceMutation } from 'features/realtors'

type AddTagsProp = TSelectAllP & {
 type?: 'lead' | 'actionPlan' | 'alert' | 'realtors' | TAutomationTypes
 selectedKeys?: any[]
 visible: boolean
 onCancel: () => void
 refetch?: () => void
 assignType: 'tag' | 'stage' | 'source'
 extraBody?: any
 customtSelectAll?: TLeadCustomSelectAll
}

const AssignTagsStagesModal: FC<AddTagsProp> = ({
 type = 'lead',
 visible,
 onCancel,
 selectedKeys,
 refetch: refresh,
 filterInfo,
 selectAll,
 unSelected,
 assignType,
 extraBody,
 customtSelectAll,
}) => {
 //  const { data, refetch, isLoading } = useGetGroupsQuery({ type: [assignType] })
 // const {} = useAuth()
 const { isLoading, hasMore, fetchMore, refetch, flatData } =
  useFetchPage2<TListTagStageSource>('get-groups-name', {
   method: 'get',
   params: `type=${assignType}`,
  })

 const [ids, setIds] = useState<string[]>([])
 const [showAdd, setAddModal] = useState(false)

 const [assignToActionPlanBulk, { isLoading: actionLoading }] =
  useAssignToActionPlanBulkMutation()
 const [assignAlertBulk, { isLoading: tagLoading }] =
  useAssignAlertBulkMutation()
 const [assignToLead, { isLoading: leadLoading }] =
  useAssignGroupToLeadBulkMutation()
 const [assingnNewsLetterBulk, { isLoading: newsLetterLoading }] =
  useAssignBulkNewsLetterMutation()
 const [addRealtorSource, { isLoading: realtorSourceLoading }] =
  useAddRealtorSourceMutation()

 const handleClose = (): void => {
  onCancel?.()
  setIds([])
 }

 const handleSubmit = async (): Promise<void> => {
  const body: TSelectAllB & {
   customtSelectAll?: TLeadCustomSelectAll
   type?: 'tag' | 'stage' | 'source'
   selectall_type?: 'tag' | 'stage' | 'source'
  } = {
   ...(type === 'Real-Estate-News-Letter' || type === 'market-update'
    ? { selectall_type: assignType }
    : { type: assignType }),
  }
  if (selectAll || customtSelectAll) {
   if (selectAll) {
    body.select_all = selectAll
   } else if (customtSelectAll) {
    body.customtSelectAll = customtSelectAll
   }
   body.unselected = unSelected
   body.filter_info = filterInfo
  }

  if (type === 'Real-Estate-News-Letter' || type === 'market-update') {
   await assingnNewsLetterBulk({
    ...body,
    type_id: ids,
    newsletter_type: type,
    ...extraBody,
   })
  } else if (type === 'actionPlan') {
   await assignToActionPlanBulk({
    ...body,
    type_id: ids,
    actionplan_id: selectAll || customtSelectAll ? [] : selectedKeys,
    ...extraBody,
   }).unwrap()
  } else if (type === 'alert') {
   await assignAlertBulk({
    ...body,
    type_id: ids,
    alert_id: selectAll || customtSelectAll ? [] : selectedKeys,
    ...extraBody,
   }).unwrap()
  } else if (type === 'realtors') {
   await addRealtorSource({
    ...body,
    realtor_id: selectAll || customtSelectAll ? [] : selectedKeys,
    source_id: ids,
    ...extraBody,
   }).unwrap()
  } else {
   await assignToLead({
    ...body,
    group_id: ids,
    lead_id: selectAll || customtSelectAll ? [] : selectedKeys,
    ...extraBody,
   }).unwrap()
  }
  handleClose?.()
  refresh?.()
 }

 //  const options = assignType === 'stage' ? data?.stage : data?.tag
 const Component = assignType === 'tag' ? Checkbox.Group : Radio.Group

 return (
  <Modal
   forceRender
   confirmLoading={
    type === 'Real-Estate-News-Letter' || type === 'market-update'
     ? newsLetterLoading
     : type === 'actionPlan'
     ? actionLoading
     : type === 'alert'
     ? tagLoading
     : type === 'realtors'
     ? realtorSourceLoading
     : leadLoading
   }
   okButtonProps={{ disabled: ids?.length === 0 }}
   onOk={handleSubmit}
   okText="Submit"
   onCancel={handleClose}
   visible={visible}
   title={`Assign ${capitalizeFirstLetter(assignType)}`}
  >
   <Spin className="w-100 text-center" spinning={isLoading}>
    {flatData && flatData?.length > 0 && (
     <>
      {/* <Col span={24}> */}
      <BaseButton
       onClick={() => setAddModal(true)}
       block
       buttonType="primary"
       customClass="mb-3"
      >
       <i className="fas fa-plus me-2" /> Add{' '}
       {capitalizeFirstLetter(assignType)}
      </BaseButton>
      {/* </Col> */}

      <div style={{ maxHeight: '400px', padding: '10px', overflow: 'auto' }}>
       <InfiniteScroll
        pageStart={0}
        loadMore={fetchMore}
        hasMore={hasMore}
        loader={
         <div className="text-center py-2">
          <Spin />
         </div>
        }
        useWindow={false}
       >
        <Component
         value={assignType === 'tag' ? ids : (ids?.[0] as any)}
         style={{ width: '100%' }}
         onChange={(e: any) => {
          setIds(assignType === 'tag' ? e : [e?.target?.value])
         }}
        >
         <Row gutter={[10, 10]}>
          {flatData?.map((v) => (
           <Col flex={1} key={v.id}>
            {assignType === 'tag' ? (
             <Checkbox className="btn-checkbox" value={v.id}>
              <span style={{ whiteSpace: 'normal' }}>{v.name}</span>
             </Checkbox>
            ) : (
             <Radio className="btn-checkbox" value={v.id}>
              <span style={{ whiteSpace: 'normal' }}>{v.name}</span>
             </Radio>
            )}
           </Col>
          ))}
         </Row>
        </Component>
       </InfiniteScroll>
      </div>
     </>
    )}
   </Spin>

   {showAdd && (
    <TSSFormModal
     onSuccess={refetch}
     type={assignType}
     onCancel={() => setAddModal(false)}
     visible={showAdd}
    />
   )}
  </Modal>
 )
}

export default AssignTagsStagesModal
