import { Input } from 'antd'
import React, { FC } from 'react'

const TrimFirstSpaceInput: FC<{
 value?: string
 onChange?: (v?: string) => void
 placeholder?: string
}> = ({ onChange, placeholder, value }) => {
 return (
  <Input
   value={value}
   placeholder={placeholder}
   onChange={({ target }) => {
    // newEvent.target.value = oldVal.replace(/ /g, '').length > 0 ? oldVal : '';
    // if (target?.value.startsWith(' ')) return
    onChange?.(target?.value?.replace(/ /g, '').length > 0 ? target?.value : '')
   }}
  />
 )
}

export default TrimFirstSpaceInput
