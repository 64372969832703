import SendWidget from 'components/shared/SendWidget'
import useAppControl from 'hooks/useAppControl'
import { FC } from 'react'

const AdminControlPanel: FC = () => {
 const { sendWidget, resetSendWidget } = useAppControl()

 return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
   {sendWidget?.show && (
    <SendWidget
     wFrom={sendWidget.widgetFrom || 'lead'}
     mode={sendWidget.mode}
     to={sendWidget.to}
     type={sendWidget.type}
     onSendSuccess={() => {
      resetSendWidget?.()
     }}
     widget={sendWidget.widget || 'email'}
    />
   )}
  </>
 )
}

export default AdminControlPanel
