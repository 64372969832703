/* eslint-disable no-nested-ternary */
import { NewListingSlug, TGloblListingKeys } from 'types'

type TSetPreLoadContent = {
 single: boolean
 conte?: TGloblListingKeys
 type?: NewListingSlug
 showPreConSingle?: boolean
}
export const setProPreLoadContent = (value: TSetPreLoadContent): string => {
 const { conte, single, type, showPreConSingle = true } = value
 const values: any = {
  sale: single ? conte?.sale_single : conte?.sale_multiple,
  rent: single ? conte?.rent_single : conte?.rent_multiple,
  leased: single ? conte?.leased_single : conte?.leased_multiple,
  sold: single ? conte?.sold_single : conte?.sold_multiple,
  'open-house': single
   ? conte?.['open-house_single']
   : conte?.['open-house_multiple'],
  preconstruction: single
   ? showPreConSingle
     ? conte?.preconstruction_single
     : ''
   : conte?.preconstruction_multiple,
 }
 return values[type ?? ''] ?? ''
}

// export const handleSignatureContent = (value: {
//  editorContent: string
//  sign: string
// }): string => {
//  const { editorContent, sign } = value || {}
//  const signatureRegex = /<div id="signature">[\s\S]*?<\/div>/
//  const addSpaceIfNeeded = (content: string): string =>
//   content.includes('<p id="sign-additional-space-top">')
//    ? ''
//    : '<p id="sign-additional-space-top">&nbsp;</p>'

//  const wrapInSignatureDiv = (content: string): string =>
//   `<div id="signature">${content}</div>`

//  const insertSignature = (content: string, signature: string): string => {
//   const space = addSpaceIfNeeded(content)
//   if (signature.includes('<body>')) {
//    return signature
//     .replace(/<body>/, `<body>${space}${wrapInSignatureDiv('')}`)
//     .replace(/<\/body>/, '</div>\n</body>')
//   }
//   return `${space}${wrapInSignatureDiv(signature)}`
//  }

//  const combineHtmlStrings = (str1?: string, str2?: string): string => {
//   const bodyContent = str2?.match(/<body>([\s\S]*)<\/body>/i)
//   if (bodyContent && bodyContent[1]) {
//    return str1?.replace('</body>', `${bodyContent[1]}</body>`) ?? ''
//   }
//   return str1 ?? ''
//  }

//  let updatedContent
//  const updateSignatureContent = (
//   editorContent: string,
//   sign: string,
//  ): string => {
//   // Regular expression to check if <div id="signature"> exists in the editor content
//   const signatureCheck = /<div\s+id=["']signature["'][^>]*>/i

//   // Extract the body content from `sign` using regex
//   const signBodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/i
//   const signMatch = signBodyRegex.exec(sign)

//   // If the <body> content is found, use it
//   const signBodyContent = signMatch ? signMatch[1] : sign // If no <body> tag, use raw content

//   if (signatureCheck.test(editorContent)) {
//    // If <div id="signature"> is found, replace its content with the sign body content
//    return editorContent.replace(
//     /(<div\s+id=["']signature["'][^>]*>)([\s\S]*?)(<\/div>)/i,
//     (match, p1, p2, p3) => {
//      // Return the <div id="signature"> with the new content inside
//      return `${p1}${signBodyContent}${p3}`
//     },
//    )
//   }
//   // If <div id="signature"> is not found, inject the sign body content wrapped in <div id="signature">
//   return editorContent.replace(
//    /(<body[^>]*>)([\s\S]*?)(<\/body>)/i,
//    (match, p1, p2, p3) => {
//     // Wrap the sign body content with <div id="signature"> and inject it into the body
//     return `${p1}<div id="signature">${signBodyContent}</div>${p3}`
//    },
//   )
//  }

//  if (signatureRegex.test(editorContent)) {
//   // // If signature div exists, update its content
//   // updatedContent = editorContent.replace(
//   //  signatureRegex,
//   //  insertSignature(editorContent, sign),
//   // )
//  } else if (editorContent.includes('<body>')) {
//   // If signature div doesn't exist, append it to the content
//   updatedContent = combineHtmlStrings(editorContent, sign)
//    .replace(
//     /<body>/,
//     `<body>${addSpaceIfNeeded(editorContent)}${wrapInSignatureDiv('')}`,
//    )
//    .replace(/<\/body>/, '</div>\n</body>')
//  } else if (editorContent === '' && sign) {
//   const bodyRegex = /(<body[^>]*>)([\s\S]*?)(<\/body>)/i
//   if (bodyRegex.test(sign)) {
//    updatedContent = sign.replace(bodyRegex, (match, p1, p2, p3) => {
//     return `${p1}<div id="signature">${p2}</div>${p3}`
//    })
//   } else {
//    updatedContent = sign
//   }

//   // updatedContent = ''
//  } else {
//   updatedContent = insertSignature(editorContent, sign)
//  }

//  return updatedContent ?? ''
// }

export const handleSignatureContent = (value: {
 editorContent: string
 sign: string
}) => {
 const { editorContent: Econtent, sign: Sign } = value || {}

 const addSpaceIfNeeded = (content: string): string =>
  content.includes('<div id="sign-additional-space-top">')
   ? ''
   : '<div id="sign-additional-space-top"><br/></div>'

 const updateSignatureContent = (
  editorContent: string,
  sign: string,
 ): string => {
  // Regular expression to check if <div id="signature"> exists in the editor content
  const signatureCheck = /<div\s+id=["']signature["'][^>]*>/i

  // Extract the body content from `sign` using regex
  const signBodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/i
  const signMatch = signBodyRegex.exec(sign)

  // If the <body> content is found, use it
  const signBodyContent = signMatch ? signMatch[1] : sign // If no <body> tag, use raw content

  if (signatureCheck.test(editorContent)) {
   // If <div id="signature"> is found, replace its content with the sign body content
   return editorContent.replace(
    /(<div\s+id=["']signature["'][^>]*>)([\s\S]*?)(<\/div>)/i,
    (match, p1, p2, p3) => {
     // Return the <div id="signature"> with the new content inside
     return `${addSpaceIfNeeded(editorContent)}${p1}${signBodyContent}${p3}`
    },
   )
  }
  // If <div id="signature"> is not found, inject the sign body content wrapped in <div id="signature">
  return editorContent.replace(
   /(<body[^>]*>)([\s\S]*?)(<\/body>)/i,
   (match, p1, p2, p3) => {
    // Wrap the sign body content with <div id="signature"> and inject it into the body
    return `${addSpaceIfNeeded(
     editorContent,
    )}${p1}<div id="signature">${signBodyContent}</div>${p3}`
   },
  )
 }
 let updatedContent
 if (Econtent === '' && Sign) {
  const bodyRegex = /(<body[^>]*>)([\s\S]*?)(<\/body>)/i
  if (bodyRegex.test(Sign)) {
   updatedContent = Sign.replace(bodyRegex, (match, p1, p2, p3) => {
    return `${p1}${addSpaceIfNeeded(Sign)}<div id="signature">${p2}</div>${p3}`
   })
  } else {
   updatedContent = Sign
  }
 } else {
  updatedContent = updateSignatureContent(Econtent, Sign)
 }

 return updatedContent ?? ''
}
