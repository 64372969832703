import { AxiosResponse } from 'axios'
import { http } from 'lib/http'
import { useCallback, useMemo, useState } from 'react'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { ApiResponce, InfinityNewType, PaginationType2 } from 'types'

type ApiTypeForm = 'mainWeb' | 'crm' | 'chat'

type UseFetchPageRes<T> = {
 data?: InfiniteData<InfinityNewType<T>>
 isLoading: boolean
 fetchMore: () => void
 hasMore?: boolean
 refetch: () => void
 isRefetching?: boolean
 flatData?: T[]
 total?: number
 meta?: { total?: number }
}

type UseFetchPageProps = {
 params?: string
 body?: any
 method?: 'get' | 'post'
 enable?: boolean
 from?: ApiTypeForm
 baseURL?: string
 customKey?: string
 onSettled?: () => void
}

const spllitUrl = (val: string): any => val.split('page=')?.[1]

const useFetchPage2 = <T>(
 key: string,
 options?: UseFetchPageProps,
): UseFetchPageRes<T> => {
 const [totalCount, setTotalCount] = useState<number>(0)
 const fetchData = async (
  props: UseFetchPageProps & { url: string },
 ): Promise<InfinityNewType<any>> => {
  try {
   const { data }: AxiosResponse<ApiResponce & PaginationType2<any>> =
    props.method === 'post'
     ? await http.post(`user/${props.url}`, props.body)
     : await http.get(`user/${props.url}`)
   if (data && data.status === 'success') {
    setTotalCount((data as any)?.meta?.total ?? 0)
    return {
     data: data.data ?? [],
     next: data?.links?.next ? spllitUrl(data?.links.next) : undefined,
     meta: data?.meta,
    }
   }
   return {
    data: [],
    next: undefined,
   }
  } catch (error) {
   return {
    data: [],
    next: undefined,
   }
  }
 }

 const {
  data,
  isLoading,
  fetchNextPage,
  hasNextPage,
  refetch,
  isRefetching,
  isFetching,
  isFetchingNextPage,
 } = useInfiniteQuery<InfinityNewType<T>>(
  options?.customKey ?? [`${key}`, options?.body ?? '', options?.params],
  ({ pageParam = 1 }) =>
   fetchData({
    method: options?.method || 'get',
    url: `${key}?page=${pageParam}${
     options?.params ? `&${options.params}` : ''
    }`,
    body: options?.body,
   }),
  {
   getNextPageParam: (lastPage) =>
    lastPage && lastPage.next && lastPage.next !== null ? lastPage.next : false,
   enabled: options?.enable,
   onSettled: options?.onSettled,
  },
 )

 const flatData = useMemo(
  () => (data && data.pages ? data?.pages.map((v) => v.data).flat() : []),
  [data],
 )
 const meta = useMemo(() => data?.pages[0]?.meta, [data])

 return {
  data,
  isLoading,
  fetchMore: fetchNextPage,
  hasMore: hasNextPage,
  refetch,
  isRefetching,
  flatData: flatData as T[],
  total: totalCount,
  meta,
 }
}

export default useFetchPage2
