import { Badge, Popover, Space, Modal, Row, Col } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch, globalToaster } from 'components/elements/global'
// import { SendEmailModal, SendTextModal } from 'components/shared/Modals'
import useAuth from 'hooks/useAuth'
import React, { FC, useMemo, useState } from 'react'
// import { useGlobal } from 'raducer/global'
import PropertyCard from 'components/shared/PropertyCard'
import useAppControl from 'hooks/useAppControl'
import { TSendWidgetState } from 'types'
import { useProListing } from '../provider/ProListingProvider'

const ProListingActionBlock: FC = () => {
 const { user } = useAuth()
 //  const { global } = useGlobal()

 const { selectedPro, setSelectedPro, filterState, type } = useProListing()
 const [showSelectProModal, setShowSelectProModal] = useState(false)
 const { updateSendEmailWidget, updateSendSMSWidget } = useAppControl()
 //  const [show, setShow] = useState<{
 //   type: null | 'mail' | 'sms'
 //   mode: 'lead' | 'tag' | null
 //  }>({
 //   type: null,
 //   mode: null,
 //  })

 //  const propertyData = useMemo(
 //   () =>
 //    selectedPro && selectedPro.length > 0
 //     ? selectedPro.map((v) => ({
 //        id: v.slug,
 //        type: v.listing_type,
 //       }))
 //     : [],

 //   [selectedPro],
 //  )

 const handleClick = (sendType: 'mail' | 'sms', mode: 'lead' | 'tag') => {
  if (sendType === 'sms' && (selectedPro.length ?? 0) > 10) {
   globalToaster({
    type: 'error',
    content: 'You can select maximum 10 items at a time',
   })
  } else {
   //  setShow({
   //   mode,
   //   type,
   //  })

   const common: TSendWidgetState = {
    widgetFrom: 'listing',
    show: true,
    type: mode,
    // propertyData,
    propFullInfo: selectedPro,
    proListingType:
     type === 'preconstruction' ? 'preconstruction' : filterState?.listing_type,
   }
   if (sendType === 'mail') {
    updateSendEmailWidget({
     ...common,
     //  ...(type === 'preconstruction' && selectedPro?.length === 1
     //   ? {
     //      disableBody: {
     //       disable: true,
     //       content:
     //        'The Pre-con single listing emails will be shared as a pre-designed template. The compose option will not be available for such emails. Please use the preview option to view the template',
     //      },
     //     }
     //   : {}),
    })
   }
   if (sendType === 'sms') {
    updateSendSMSWidget({
     ...common,
    })
   }
  }
 }
 const buttons = [
  {
   tooltipText: 'Send Email',
   icon: <i className="fas fa-envelope me-2" />,
   onClick: () => handleClick('mail', 'lead'),
  },
  {
   tooltipText: 'Email to Tag',
   icon: <i className="fas fa-envelope me-2" />,
   onClick: () => handleClick('mail', 'tag'),
  },

  ...(user?.twilio_phone
   ? [
      {
       tooltipText: 'Send SMS',
       icon: <i className="fas fa-comment me-2" />,
       onClick: () => handleClick('sms', 'lead'),
      },
      {
       tooltipText: 'SMS to Tag',
       icon: <i className="fas fa-comment me-2" />,
       onClick: () => handleClick('sms', 'tag'),
      },
     ]
   : []),
  ...(selectedPro?.length > 0
   ? [
      {
       tooltipText: 'Clear',
       icon: <i className="far fa-undo me-2" />,
       onClick: () => setSelectedPro([]),
      },
     ]
   : []),
 ]

 //  const commonModalProps: any = {
 //   visible: true,
 //   sendType: show?.mode,
 //   onCancel: () =>
 //    setShow({
 //     type: null,
 //     mode: null,
 //    }),
 //   onSendSuccess: () =>
 //    setShow({
 //     type: null,
 //     mode: null,
 //    }),
 //   modalTypes: 'listing',
 //   removeTemplateDropdown: true,
 //   updateSelectedProp: (val: any) => setSelectedPro?.(val),
 //   propertyData,
 //   propFullInfo: selectedPro,
 //   listingType:
 //    type === 'preconstruction' ? 'preconstruction' : filterState?.listing_type,
 //   allData: selectedPro,
 //  }

 return (
  <Space>
   {selectedPro.length > 0 && (
    <BaseButton type="link" onClick={() => setShowSelectProModal(true)}>
     Selected properties
     <Badge className="ms-1" count={selectedPro.length ?? 0} />
    </BaseButton>
   )}

   {buttons?.map((item, n) => (
    <BaseButton
     key={n}
     tooltipText={item?.tooltipText}
     type="primary"
     onClick={item?.onClick}
     disabled={!selectedPro.length}
     icon={item?.icon}
    >
     {item?.tooltipText}
    </BaseButton>
   ))}

   {showSelectProModal && (
    <Modal
     title="Selected Properties"
     visible={showSelectProModal}
     onCancel={() => setShowSelectProModal(false)}
     footer={false}
     width={1024}
    >
     <Row gutter={[16, 8]}>
      {selectedPro?.map((item, index) => (
       <Col key={index} span={12}>
        <PropertyCard
         item={{
          ...item,
          ...(type === 'preconstruction'
           ? { listing_type: 'preconstruction' }
           : {}),
         }}
         customCloseBtn={
          <BtnAnch
           onClick={() => {
            if (selectedPro?.length === 1) {
             setShowSelectProModal(false)
            }
            if (selectedPro?.find?.((v) => v.ml_num === item.ml_num)) {
             setSelectedPro?.(
              selectedPro.filter((v) => v.ml_num !== item.ml_num),
             )
            }
           }}
           className="pro-selction"
          >
           <i className="fal fa-times" />
          </BtnAnch>
         }
        />
       </Col>
      ))}
     </Row>
    </Modal>
   )}
  </Space>
 )
}

export default ProListingActionBlock
