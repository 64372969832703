import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { SvgDeleteIcon, SvgStageIcon, SvgTagsIcon } from 'components/icons'
import { AssignToLeadModal } from 'components/shared/Modals'
import AssignTagsStagesModal from 'components/shared/Modals/AssignTagsStagesModal'
import { TSelectAllP } from 'features/leads'
import { FC, useState } from 'react'
import { globalConfirm } from 'components/elements/global'
import AssignToSourceModal from 'components/shared/Modals/AssignToSourceModal'
import { useDeleteBulkAlertMutation } from '../api'
import { TAlertFeedType } from '../types'

const AlertBulkActionBlock: FC<
 TSelectAllP & {
  onClickAdd?: () => void
  selectedKeys?: any[]
  alertRefresh?: () => void
  type: TAlertFeedType
 }
> = ({
 selectedKeys,
 onClickAdd,
 alertRefresh,
 selectAll,
 unSelected,
 filterInfo,
 type,
}) => {
 const [showModal, setShowModal] = useState<
  false | 'tag' | 'stage' | 'lead' | 'source'
 >(false)

 const [deleteAlerts] = useDeleteBulkAlertMutation()
 const handleDeleteAlerts = () => {
  globalConfirm({
   content: 'Are you sure you want to delete the selected alerts?',
   onOk: async () => {
    await deleteAlerts({
     id: selectedKeys,
     feed: type,
     ...(selectAll
      ? {
         unselected: unSelected,
         select_all: selectAll,
         filter_info: filterInfo,
        }
      : {}),
    }).unwrap()
    alertRefresh?.()
   },
  })
 }

 const buttons = [
  {
   tooltipText: 'Add Alert',
   onClick: onClickAdd,
   buttonType: 'gamma',
   disabled: false,
   icon: <i className="far fa-plus" />,
  },
  {
   tooltipText: 'Assign to Lead',
   onClick: () => setShowModal('lead'),
   buttonType: 'alpha',
   icon: <i className="fas fa-user-tag" />,
  },
  {
   tooltipText: 'Assign to Tag',
   onClick: () => setShowModal('tag'),
   buttonType: 'alpha',
   icon: <i className="far fa-tags" style={{ fontSize: '17px' }} />,
  },
  {
   tooltipText: 'Assign to Stage',
   onClick: () => setShowModal('stage'),
   buttonType: 'alpha',
   icon: <i className="far fa-th" style={{ fontSize: '17px' }} />,
  },
  {
   tooltipText: 'Assign to Source',
   onClick: () => setShowModal('source'),
   buttonType: 'alpha',
   icon: <i className="far fa-globe" style={{ fontSize: '17px' }} />,
  },
  {
   tooltipText: 'Delete',
   onClick: handleDeleteAlerts,
   buttonType: 'beta',
   icon: <SvgDeleteIcon />,
  },
 ]

 const commonModalProps = {
  selectAll,
  unSelected,
  filterInfo,
  onCancel: () => setShowModal(false),
  type: 'alert',
  selectedKeys,
  visible: true,
 }

 return (
  <>
   <Space>
    {buttons?.map(({ disabled = !selectedKeys?.length, ...props }, i) => (
     <BaseButton
      disabled={disabled}
      shape="circle"
      key={i}
      {...(props as any)}
     />
    ))}
   </Space>
   {(showModal === 'tag' ||
    showModal === 'stage' ||
    showModal === 'source') && (
    <AssignTagsStagesModal
     {...(commonModalProps as any)}
     refetch={alertRefresh}
     assignType={showModal}
     extraBody={{ feed: type }}
    />
   )}

   {/* {showModal === 'source' && (
    <AssignToSourceModal
     refetch={alertRefresh}
     {...(commonModalProps as any)}
    />
   )} */}

   {showModal === 'lead' && (
    <AssignToLeadModal alertType={type} {...(commonModalProps as any)} />
   )}
  </>
 )
}
export default AlertBulkActionBlock
