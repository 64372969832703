import Axios, { AxiosRequestConfig } from 'axios'

import storage from 'utils/storage'
import { API_BASE_URL } from 'config'
import { RootState, store } from 'store'

function authRequestInterceptor(config: AxiosRequestConfig) {
 const token = storage.getToken()
 const APP_KEY = (store.getState() as RootState).global.listing_key

 if (config.headers) {
  if (token) {
   config.headers.authorization = `Bearer ${token}`
  }
  if (APP_KEY) {
   config.headers.APPKEY = `${APP_KEY}`
  }
  config.headers.Accept = 'application/json'
 }
 return config
}

export const http = Axios.create({
 baseURL: API_BASE_URL,
 headers: {},
})

http.interceptors.request.use(authRequestInterceptor)
http.interceptors.response.use(
 (response) => response,
 (error) => {
  if (error && error.response) {
   if (error.response?.status === 401) {
    storage.clearToken()
    // window.location.reload()
    window.location.href = '/auth/login'
   }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const message = error.response?.data?.message || error.message
  // useNotificationStore.getState().addNotification({
  //   type: 'error',
  //   title: 'Error',
  //   message,
  // })

  return Promise.reject(error)
 },
)
