/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import { Checkbox, Empty, Input, Radio, Spin } from 'antd'
import classNames from 'classnames'
import React, {
 Dispatch,
 FC,
 SetStateAction,
 useCallback,
 useEffect,
 useState,
} from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useDebounce } from 'react-use'
import { TProLeadsSelectAll } from 'components/shared/SendWidget/EmailSendWidget'
import { AvatarStatus } from '../AvatarStatus'
import { BtnAnch } from '../global'

export type BaseSelectorOption<RecordType = any> = {
 title?: any
 subTitle?: any
 id?: any
 extra?: RecordType
}

type BaseSelectorProps<RecordType> = {
 type?: 'single' | 'multiple'
 value?: BaseSelectorOption<RecordType>[]
 options?: BaseSelectorOption<RecordType>[]
 isLoading?: boolean
 onSearch?: (key?: any) => void
 searchPlaceholder?: string
 showSearch?: boolean
 fetchMore?: () => void
 hasMore?: boolean
 totalCount?: number
 onChange?: (records: BaseSelectorOption<RecordType>[]) => void
 showAvatar?: boolean
 valueBy?: 'id' | 'name'
 setProLeadsSelectAll?: Dispatch<SetStateAction<TProLeadsSelectAll | undefined>>
 proLeadsSelectAll?: TProLeadsSelectAll
 showEmpty?: boolean
}

const InternalBaseSelector = <RecordType extends object = any>(
 props: BaseSelectorProps<RecordType>,
 ref: React.ForwardedRef<HTMLDivElement>,
) => {
 const {
  onSearch,
  showSearch = true,
  isLoading,
  hasMore,
  fetchMore,
  searchPlaceholder = 'Search',
  options,
  onChange,
  type = 'multiple',
  value,
  showAvatar = true,
  valueBy = 'id',
  proLeadsSelectAll,
  setProLeadsSelectAll,
  totalCount,
  showEmpty,
 } = props

 const [selected, setSelected] = useState<BaseSelectorOption[]>([])

 const [searchKey, setSearchKey] = useState('')

 const { searchKey: searchKeyProSelectAll, show: showProSelectAll } =
  proLeadsSelectAll || {}
 useEffect(() => {
  if (proLeadsSelectAll?.show && proLeadsSelectAll?.searchKey) {
   setSearchKey(proLeadsSelectAll?.searchKey)
  }
 }, [searchKeyProSelectAll, showProSelectAll])
 useEffect(() => {
  if (searchKey && proLeadsSelectAll?.show && proLeadsSelectAll?.selectAll) {
   setProLeadsSelectAll?.((prv: any) => ({
    ...prv,
    searchKey,
   }))
  }
 }, [searchKey, proLeadsSelectAll?.show, proLeadsSelectAll?.selectAll])

 useDebounce(
  () => {
   if (searchKey.trim().length > 2) {
    onSearch?.(searchKey)
   } else {
    onSearch?.('')
   }
  },
  200,
  [searchKey],
 )

 useEffect(() => {
  if (value) {
   setSelected(value)
  }
 }, [value])

 const isActive = useCallback(
  (id?: any) => {
   if (proLeadsSelectAll?.show && proLeadsSelectAll?.selectAll) {
    return !proLeadsSelectAll?.unSelected?.includes(id)
   }
   return !!selected?.find?.((v) =>
    valueBy === 'id' ? v?.id === id : v === id,
   )
  },
  [selected, proLeadsSelectAll?.show, proLeadsSelectAll?.selectAll],
 )

 useEffect(() => {
  if (proLeadsSelectAll?.show && proLeadsSelectAll?.selectAll) {
   const result =
    options
     ?.map((i) => i?.subTitle)
     .filter((k) => !proLeadsSelectAll?.unSelected?.includes(k)) ?? []
   setSelected(result)
  }
 }, [
  proLeadsSelectAll?.show,
  proLeadsSelectAll?.selectAll,
  options,
  proLeadsSelectAll?.unSelected,
 ])

 const onSelectAll = (): void => {
  setSelected([])
  onChange?.([])
  setProLeadsSelectAll?.((prv: any) => ({
   ...prv,
   searchKey,
   selectAll: !proLeadsSelectAll?.selectAll,
   unSelected: [],
   filterInfo: JSON.stringify({ search: searchKey }),
   selectedCount: !hasMore ? selected?.length ?? 1 : totalCount ?? 0,
   showMoreLabel:
    !proLeadsSelectAll?.selectAll === true &&
    (hasMore ||
     (selected?.length ?? 0) + (proLeadsSelectAll?.unSelected?.length ?? 0) ===
      (totalCount ?? 0)),
  }))
 }
 return (
  <div className="base-selecter-blk">
   {showSearch && (
    <div className="selector-search px-3 py-1">
     <Input.Search
      value={searchKey}
      onChange={(e) => {
       setSearchKey(e.target.value)
      }}
      placeholder={searchPlaceholder}
     />
    </div>
   )}
   {proLeadsSelectAll?.show && !isLoading && (options?.length ?? 0) > 0 && (
    <div
     className="d-flex justify-content-end align-items-center"
     style={{ fontWeight: '500', marginBottom: '8px' }}
    >
     <span
      style={{ paddingRight: '6px', cursor: 'pointer', userSelect: 'none' }}
      onClick={() => onSelectAll()}
     >
      Select All
     </span>
     <Checkbox
      className="d-flex justify-content-end"
      style={{
       padding: '0 15px',
       paddingRight: (options?.length ?? 0) > 7 ? '22px' : '15px',
      }}
      checked={proLeadsSelectAll?.selectAll}
      onChange={({ target }) => {
       onSelectAll()
      }}
     />
    </div>
   )}
   {isLoading ? (
    <div className="py-4 text-center">
     <Spin />
    </div>
   ) : showEmpty ? (
    <Empty className="py-4" description="No Result Found" />
   ) : (
    <ul>
     <InfiniteScroll
      useWindow={false}
      loader={
       <div className="text-center py-2">
        <Spin />
       </div>
      }
      hasMore={hasMore || false}
      loadMore={() => fetchMore?.()}
     >
      {options?.map((item, i) => (
       <li key={i}>
        <BtnAnch
         className={classNames({
          active: isActive(valueBy === 'id' ? item?.id : item?.subTitle),
         })}
         onClick={() => {
          if (
           proLeadsSelectAll?.show &&
           proLeadsSelectAll?.selectAll &&
           !proLeadsSelectAll?.unSelected?.includes(item?.subTitle)
          ) {
           setProLeadsSelectAll?.((prv: any) => ({
            ...prv,
            unSelected: [...prv.unSelected, item?.subTitle],
            selectedCount: (prv?.selectedCount ?? 1) - 1,
           }))
          } else if (
           proLeadsSelectAll?.show &&
           proLeadsSelectAll?.selectAll &&
           proLeadsSelectAll?.unSelected?.includes(item?.subTitle)
          ) {
           setProLeadsSelectAll?.((prv: any) => ({
            ...prv,
            unSelected: prv?.unSelected?.filter?.(
             (v: any) => v !== item?.subTitle,
            ),
            selectedCount: (prv?.selectedCount ?? 1) + 1,
           }))
          } else {
           setSelected((prv) => {
            let newData: BaseSelectorOption[] = prv
            if (type === 'multiple') {
             if (
              newData &&
              newData.length > 0 &&
              (valueBy === 'id'
               ? newData?.find?.((v) => v?.id === item?.id)
               : newData?.find?.((v) => v === item?.subTitle))
             ) {
              newData =
               valueBy === 'id'
                ? newData?.filter?.((v) => v?.id !== item?.id)
                : newData?.filter?.((v) => v !== item?.subTitle)
             } else if (valueBy === 'name') {
              // newData?.push?.(item?.subTitle)
              newData = [...newData, item?.subTitle]
             } else {
              newData = [...newData, item]
              // newData?.push?.(item)
             }
            } else {
             newData = [item]
            }
            onChange?.(newData)
            return [...newData]
           })
          }
         }}
        >
         <AvatarStatus
          showAvatar={showAvatar}
          action={
           type === 'multiple' ? (
            <Checkbox
             checked={isActive(valueBy === 'id' ? item?.id : item?.subTitle)}
            />
           ) : (
            <Radio
             checked={isActive(valueBy === 'id' ? item?.id : item?.subTitle)}
            />
           )
          }
          title={item?.title}
          subTitle={item?.subTitle}
         />
        </BtnAnch>
       </li>
      ))}
     </InfiniteScroll>
    </ul>
   )}
  </div>
 )
}

const BaseSelector = React.forwardRef(InternalBaseSelector) as <
 RecordType extends object = any,
>(
 props: React.PropsWithChildren<BaseSelectorProps<RecordType>> & {
  ref?: React.Ref<HTMLDivElement>
 },
) => React.ReactElement

export default BaseSelector
