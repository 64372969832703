import { Row, Col, Card, Tag, Modal, Input, Form } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import {
 useBlockAndUnblockEmailMutation,
 useBlockAndUnblockSmsMutation,
 leadApi,
 useDeleteEmailAddressMutation,
 useDeletePhoneAddressMutation,
 useDeleteUserAddressesMutation,
 useGetLeadProfileQuery,
 useGetLeadQuery,
 useUpdateNameMutation,
} from 'features/leads/api'
import AddAddressesModal from 'features/leads/components/AddAddressesModal'
import AddEmailModal from 'features/leads/components/AddEmailModal'
import AddPhoneModal from 'features/leads/components/AddPhoneModal'
import {
 AddAddresses,
 AddEmailType,
 AddPhoneType,
 TGetLead,
} from 'features/leads/types'
import { FC, useMemo, useState } from 'react'
import { TModalState } from 'types'
import { useGlobal } from 'raducer/global'
import useAppControl from 'hooks/useAppControl'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { queryClient } from 'lib/react-query'
import { useAppDispatch } from 'hooks'
// import { updateLeadDetails } from 'features/leads/functions'
import { useForm } from 'antd/lib/form/Form'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import EditSingleField from '../EditSingleField'
import ProInnerCard from '../ProInnerCard'
import SimpleBlockItem from '../SimpleBlockItem'
import AutomationBlockBox from './Boxes/AutomationBlockBox'
import AppointmentsBlock from './Boxes/AppointmentsBlock'
import BackgroundBlock from './Boxes/BackgroundBlock'
import CityBlock from './Boxes/CityBlock'
import FIleListBlock from './Boxes/FIleListBlock'
// import AlertBox from './Boxes/AlertBox'
// import PriceRangeblock from './Boxes/PriceRangeblock'
import SoldListingAlertBlock from './Boxes/SoldListingAlertBlock'
import SourceBlock from './Boxes/SourceBlock'
import StageBlock from './Boxes/StageBlock'
import TagsBlock from './Boxes/TagsBlock'
import TasksBlock from './Boxes/TasksBlock'
import LeadBasicDetails from './_components/LeadBasicDetails'
import AlertBox from './Boxes/AlertBlock'
import DeleteLeadBlock from './Boxes/DeleteLead'
import EditLeadNameModal from '../EditLeadNameModal'

const AddButton: FC<{
 onClick?: () => void
}> = ({ onClick }) => (
 <BaseButton onClick={onClick} size="small" type="primary">
  {' '}
  <i className="fas fa-plus" />{' '}
 </BaseButton>
)

const ProfileSection: FC<{
 data1?: TGetLead
 leadId: any
 onClose?: () => void
 leadListIndex?: number
}> = ({ data1, leadId, onClose, leadListIndex }) => {
 const { filterParams } = useLeadFilter()
 const d = useAppDispatch()
 const { updateSendSMSWidget, updateSendEmailWidget } = useAppControl()
 const { realtorId } = useLeadDetails()
 const { data: data2 } = useGetLeadProfileQuery(
  { id: leadId, ...(realtorId ? { realtor_id: realtorId } : {}) },
  { skip: !leadId, refetchOnMountOrArgChange: true },
 )

 const data = useMemo(() => data2 ?? data1, [data1, data2])

 const [deleteEmail] = useDeleteEmailAddressMutation()
 const [deletePhoneAddress] = useDeletePhoneAddressMutation()
 const [deleteUserAddress] = useDeleteUserAddressesMutation()
 const [blockAndUnblockEmailApi] = useBlockAndUnblockEmailMutation()
 const [blockAndUnblockSmsApi] = useBlockAndUnblockSmsMutation()
 const [showAddEmail, setShowAddEmail] = useState<TModalState<AddEmailType>>({
  show: false,
  mode: 'ADD',
 })
 const [showAddPhone, setShowAddPhone] = useState<TModalState<AddPhoneType>>({
  show: false,
  mode: 'ADD',
 })

 const [showAddresses, setShowAddresses] = useState<TModalState<AddAddresses>>({
  show: false,
  mode: 'ADD',
 })

 const [isEditModal, setIsEditModal] = useState<{
  show: boolean
  name?: string
 }>({ show: false })

 //  const [updateName, { isLoading: updateNameLoading }] = useUpdateNameMutation()

 //  const [sendSMS, setSendSMS] = useState<TModalState<any>>({
 //   show: false,
 //  })
 //  const [sendEmail, setSendEmail] = useState<TModalState<any>>({
 //   show: false,
 //  })

 const { global } = useGlobal()

 const handleDeleteEmail = (id?: any[]) => {
  globalConfirm({
   content: 'Are you sure want to delete?',
   onOk: async () => {
    await deleteEmail({ id }).unwrap()
   },
  })
 }

 const handleDeletePhone = (id?: any[]) => {
  globalConfirm({
   content: 'Are you sure you want to delete number?',
   onOk: async () => {
    await deletePhoneAddress({ id }).unwrap()
   },
  })
 }

 const HandleDeleteAddresses = (id?: any) => {
  globalConfirm({
   content: 'Are you sure you want to delete the address?',
   onOk: async () => {
    await deleteUserAddress({ id }).unwrap()
   },
  })
 }

 const blockEmail = async (email: string): Promise<any> => {
  globalConfirm({
   content:
    'All incoming emails from this email ID will be blocked and skipped the inbox.',
   title: 'Block this Email',
   okText: 'Confirm',
   onOk: async () => {
    await blockAndUnblockEmailApi({
     lead_id: leadId,
     email,
     report: 'Block',
    }).unwrap()
   },
  })
 }

 const unblockEmail = async (email: string): Promise<any> => {
  globalConfirm({
   content:
    'The incoming emails from this email ID will be enabled and will be shown in the inbox.',
   title: 'Un-Block this Email',
   okText: 'Confirm',
   onOk: async () => {
    await blockAndUnblockEmailApi({
     lead_id: leadId,
     email,
     report: 'Unblock',
    }).unwrap()
   },
  })
 }

 const blockSms = async (phone: string): Promise<any> => {
  globalConfirm({
   content:
    'All incoming text messages & calls from this contact number will be blocked and skipped the inbox.',
   title: 'Block this Number',
   okText: 'Confirm',
   onOk: async () => {
    await blockAndUnblockSmsApi({
     lead_id: leadId,
     number: phone,
     report: 'Block',
    }).unwrap()
   },
  })
 }

 const unblockSms = async (phone: string): Promise<any> => {
  globalConfirm({
   content:
    'The incoming text messages and calls from this contact number will be enabled.',
   title: 'Un-Block this Number',
   okText: 'Confirm',
   onOk: async () => {
    await blockAndUnblockSmsApi({
     lead_id: leadId,
     number: phone,
     report: 'Unblock',
    }).unwrap()
   },
  })
 }

 const isTransferred = data?.lead_transfer

 //  const [editNameForm] = useForm<{ name: string }>()

 const updateLeadCache = (upVal: any): void => {
  d(
   leadApi.util.updateQueryData('getLeadProfile', { id: data?.id }, (draft) => {
    return { ...draft, ...upVal }
   }),
  )
  d(
   leadApi.util.updateQueryData('getLead', { id: data?.id }, (draft) => {
    return { ...draft, ...upVal }
   }),
  )
  if (leadListIndex !== undefined && leadListIndex !== null) {
   queryClient.setQueryData(
    ['get-leads', filterParams, null],
    (oldData: any) => {
     const totalItemsPerPage = 24
     const pageIndex = Math.floor(leadListIndex / totalItemsPerPage)
     const dataIndex = leadListIndex % totalItemsPerPage
     if (oldData?.pages?.[pageIndex]?.data?.[dataIndex]) {
      Object.assign(oldData.pages[pageIndex].data[dataIndex], upVal)
     }
     return oldData
    },
   )
  }
 }
 return (
  <>
   <div className="profileblock">
    <Row gutter={[15, 15]}>
     <Col span={12}>
      <Card className="mb-3" title="Name" type="inner">
       <Row gutter={[15, 15]}>
        {data?.name && (
         <Col span={24}>
          <SimpleBlockItem
           text={data?.name}
           onEdit={
            isTransferred
             ? () => {
                setIsEditModal({ show: true, name: data?.name })
                // editNameForm.setFieldsValue({ name: data?.name })
               }
             : undefined
           }
          />
         </Col>
        )}
       </Row>
      </Card>
      <Card
       className="mb-3"
       extra={
        isTransferred ? (
         <AddButton
          onClick={() => setShowAddPhone({ show: true, mode: 'ADD' })}
         />
        ) : undefined
       }
       title="Phone Numbers"
       type="inner"
      >
       <Row gutter={[15, 15]}>
        {data?.phone_numbers &&
         data.phone_numbers.map((item, i) => (
          <Col span={24} key={i}>
           <SimpleBlockItem
            // call={item?.label !== 'Bad' && isTransferred}
            // contentClassName={item?.label === 'Bad' ? 'bad-label' : ''}
            // text={`+${item?.phone} `}
            contentNode={
             <span className="content">
              <span
               className={`align-items-center ${
                item?.label === 'Bad' ? 'bad-label' : ''
               }`}
              >
               {' '}
               +{item?.phone}
              </span>
              {item?.report === 'Block' && (
               //  <div className="blk-tag">Blocked</div>
               <Tag
                color="red"
                style={{ height: 'max-content', marginLeft: '5px' }}
               >
                Blocked
               </Tag>
              )}
             </span>
            }
            onDelete={
             isTransferred ? () => handleDeletePhone([item.id]) : undefined
            }
            onEdit={
             isTransferred
              ? () =>
                 setShowAddPhone({
                  show: true,
                  mode: 'EDIT',
                  data: item as any,
                 })
              : undefined
            }
            showSMS={item?.label !== 'Bad' && isTransferred}
            onSendSMS={() =>
             //  setSendSMS({
             //   show: true,
             //   data: item?.number,
             //  })
             updateSendSMSWidget({
              show: true,
              widgetFrom: 'lead',
              to: item?.number,
             })
            }
            onBlock={
             isTransferred && !item?.report
              ? () => blockSms(item?.number)
              : undefined
            }
            onUnblock={
             isTransferred && item?.report === 'Block'
              ? () => unblockSms(item?.number)
              : undefined
            }
            blockType="sms"
            tooltip={{
             block: 'Stop Receiving SMS from this Number',
             unblock: 'Unblock and start receiving SMS from this Number',
             sendSms: 'Send SMS Message',
             edit: 'Edit Contact Number',
             delete: 'Delete Contact Number',
            }}
           />
          </Col>
         ))}
       </Row>
      </Card>
      <Card
       className="mb-3"
       extra={
        isTransferred ? (
         <AddButton
          onClick={() => setShowAddEmail({ show: true, mode: 'ADD' })}
         />
        ) : undefined
       }
       title="Email Addresses"
       type="inner"
      >
       <Row gutter={[15, 15]}>
        {data?.email_addresses &&
         data.email_addresses.map((item, i) => (
          <Col span={24} key={i}>
           <SimpleBlockItem
            contentNode={
             <span className="content align-items-center">
              <span
               //  {...(item?.report === 'Block'
               //   ? {
               //      style: { width: 'calc(100% - 44px)' },
               //     }
               //   : {})}
               className={`align-items-center text-break ${
                item?.label === 'Bad' ? 'bad-label' : ''
               }`}
              >
               {item.email}
               {item?.report === 'Block' && (
                //  <div className="blk-tag">Blocked</div>
                <Tag
                 color="red"
                 style={{ height: 'max-content', marginLeft: '5px' }}
                >
                 Blocked
                </Tag>
               )}
              </span>
              {/* {item?.report === 'Block' && (
               //  <div className="blk-tag">Blocked</div>
               <Tag
                color="red"
                style={{ height: 'max-content', marginLeft: '5px' }}
               >
                Blocked
               </Tag>
              )} */}
             </span>
            }
            // contentClassName={`${
            //  item?.label === 'Bad' ? 'bad-label' : ''
            // } text-break`}
            onDelete={
             isTransferred ? () => handleDeleteEmail([item.id]) : undefined
            }
            onEdit={
             isTransferred
              ? () =>
                 setShowAddEmail({
                  show: true,
                  mode: 'EDIT',
                  data: item as any,
                 })
              : undefined
            }
            // text={item.email}
            showEmail={item?.label !== 'Bad' && isTransferred}
            onSendEmail={() =>
             isTransferred
              ? updateSendEmailWidget({
                 show: true,
                 widgetFrom: 'lead',
                 to: item?.email,
                })
              : undefined
            }
            onBlock={
             isTransferred && !item?.report
              ? () => blockEmail(item?.email)
              : undefined
            }
            onUnblock={
             isTransferred && item?.report === 'Block'
              ? () => unblockEmail(item?.email)
              : undefined
            }
            blockType="email"
            tooltip={{
             block: 'Stop Receiving Emails from this Address',
             unblock: 'Un-block and Start Receiving Emails from this Address',
             sendMail: 'Send Email Message',
             edit: 'Edit Email Address',
             delete: 'Delete Email Address',
            }}
           />
          </Col>
         ))}
       </Row>
      </Card>
      <Card
       className="mb-3"
       extra={
        isTransferred ? (
         <AddButton
          onClick={() => setShowAddresses({ show: true, mode: 'ADD' })}
         />
        ) : undefined
       }
       title="Addresses"
       type="inner"
      >
       <Row gutter={[15, 15]}>
        {data?.addresses &&
         data.addresses.map((item, index) => (
          <Col span={24} key={index}>
           <SimpleBlockItem
            onEdit={
             isTransferred
              ? () =>
                 setShowAddresses({
                  mode: 'EDIT',
                  show: true,
                  data: item as any,
                 })
              : undefined
            }
            text={[
             item.number ?? '',
             item.street ?? '',
             item.city ?? '',
             item.province ?? '',
             item?.zip ?? '',
            ].join(', ')}
            onDelete={
             isTransferred ? () => HandleDeleteAddresses?.(item.id) : undefined
            }
           />
          </Col>
         ))}
       </Row>
      </Card>
      <div className="mb-3">
       <LeadBasicDetails data={data} isTransferred={isTransferred} />
      </div>{' '}
      <FIleListBlock data={data?.leadfile} isTransferred={isTransferred} />
      <div className="mt-3">
       <BackgroundBlock
        background={data?.background}
        isTransferred={isTransferred}
       />
      </div>
      <div className="mt-3">
       <CityBlock city={data?.city} isTransferred={isTransferred} />
      </div>
      {isTransferred && (
       <div className="mt-3">
        <DeleteLeadBlock onClose={onClose} leadId={data?.id} />
       </div>
      )}
     </Col>
     <Col span={12}>
      <div className="mb-3">
       <TagsBlock
        tags={data?.tags}
        leadId={data?.id}
        isTransferred={isTransferred}
       />
      </div>
      <div className="mb-3">
       <StageBlock
        stages={data?.stages}
        leadId={data?.id}
        isTransferred={isTransferred}
       />
      </div>
      {data?.lead_sources && data?.lead_sources?.length > 0 && (
       <div className="mb-3">
        <SourceBlock sources={data?.lead_sources as any} />
       </div>
      )}
      {!(global?.listing_plan === 'Pre-Condo') && (
       <>
        <div className="mb-3">
         <AlertBox
          nla={data?.new_listing_alert}
          leadId={data?.id}
          isTransferred={isTransferred}
          type="forsale"
         />
        </div>
        {global?.user_type_sold && (
         <div className="mb-3">
          <AlertBox
           nla={data?.sold_listing_alert}
           leadId={data?.id}
           isTransferred={isTransferred}
           type="sold"
          />
         </div>
        )}
        {global?.open_house && (
         <div className="mb-3">
          <AlertBox
           nla={data?.open_house_alert}
           leadId={data?.id}
           isTransferred={isTransferred}
           type="openhouse"
          />
         </div>
        )}
        <div className="mb-3">
         <AlertBox
          nla={data?.price_drop_alert}
          leadId={data?.id}
          isTransferred={isTransferred}
          type="price-drop"
         />
        </div>
       </>
      )}
      <div className="mb-3">
       <AutomationBlockBox
        plans={data?.plans}
        leadId={data?.id}
        isTransferred={isTransferred}
        from="action-plan"
       />
      </div>
      <div className="mb-3">
       <AutomationBlockBox
        plans={data?.realEstateNewsletter}
        leadId={data?.id}
        isTransferred={isTransferred}
        from="real-estate-news"
       />
      </div>
      <div className="mb-3">
       <AutomationBlockBox
        plans={data?.marketUpdate}
        leadId={data?.id}
        isTransferred={isTransferred}
        from="market-updates"
       />
      </div>
      <div className="mb-3">
       <TasksBlock
        tasks={data?.tasks}
        leadId={data?.id}
        isTransferred={isTransferred}
       />
      </div>
      <AppointmentsBlock
       appointments={data?.appointments}
       leadId={data?.id}
       isTransferred={isTransferred}
      />
     </Col>
    </Row>
   </div>
   {data?.id && (
    <AddEmailModal
     mode={showAddEmail.mode}
     editData={showAddEmail.data}
     leadId={data?.id}
     onCancel={() => setShowAddEmail({ show: false, mode: 'ADD' })}
     visible={showAddEmail.show}
    />
   )}
   <AddPhoneModal
    visible={showAddPhone.show}
    onCancel={() => setShowAddPhone({ show: false, mode: 'ADD' })}
    editData={showAddPhone.data}
    leadID={data?.id}
    mode={showAddPhone.mode}
   />
   <AddAddressesModal
    visible={showAddresses.show}
    onCancel={() => setShowAddresses({ show: false, mode: 'ADD' })}
    mode={showAddresses.mode}
    leadId={data?.id}
    EditData={showAddresses.data}
   />
   {isEditModal?.show && (
    <EditLeadNameModal
     id={data?.id}
     name={isEditModal?.name ?? ''}
     onCancel={() => setIsEditModal({ show: false })}
     updateLeadCache={updateLeadCache}
     visible={isEditModal?.show}
    />
   )}
  </>
 )
}

export default ProfileSection
