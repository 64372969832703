/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Input, InputRef, Spin } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { EmailTemplateModal } from 'components/shared/Modals'
import TextTemplateModal from 'components/shared/Modals/TextTemplateModal'
import { TGetTemplateFolder } from 'features/templates/types'
import useFetchPage2 from 'hooks/useFetchPage2'
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react'

interface Template {
 id: number
 name: string
}

interface SelectedValue {
 template?: string
 value?: number
}

interface TemplateTreeSelectProps {
 type?: 'Email' | 'SMS'
 onChange?: (id: any) => void
 value?: any
 templateName?: string
 showButtons: boolean
 styles?: {
  suggestionBox?: CSSProperties
 }
}

const TemplateTreeSelect: FC<TemplateTreeSelectProps> = ({
 type,
 onChange,
 templateName,
 value,
 showButtons,
 styles,
}) => {
 const [closedFolders, setClosedFolders] = useState<Set<number>>(new Set())
 const [selectedValue, setSelectedValue] = useState<SelectedValue | null>(null)
 const [inputValue, setInputValue] = useState<string>('')
 const [searchKey, setSearchKey] = useState<string>('')
 const [apiSearchKey, setApiSearchKey] = useState<string>('')
 const [isOpen, setIsOpen] = useState<boolean>(false)
 const [isSearching, setIsSearching] = useState<boolean>(false)
 const componentRef = useRef<HTMLDivElement>(null)
 const inputRef = useRef<InputRef>(null)
 const [showModal, setShowModal] = useState<{
  show: boolean
  mode: 'ADD' | 'EDIT' | null
  id: number | null
 }>({
  show: false,
  mode: null,
  id: null,
 })

 const { isLoading, hasMore, fetchMore, flatData } =
  useFetchPage2<TGetTemplateFolder>('template-folders', {
   method: 'get',
   params: `type=${type?.toLocaleLowerCase()}&search=${apiSearchKey}`,
  })

 useEffect(() => {
  if (isSearching) {
   setApiSearchKey(searchKey)
  }
 }, [searchKey, isSearching])

 useEffect(() => {
  setSelectedValue((prv) => ({
   ...prv,
   ...(value ? { value: Number(value) } : {}),
  }))
 }, [value])

 useEffect(() => {
  setSelectedValue((prv) => ({
   ...prv,
   template: templateName ?? '',
  }))
  setInputValue(templateName ?? '')
 }, [templateName])

 useEffect(() => {
  const handleClickOutside = (event: MouseEvent): void => {
   if (
    componentRef.current &&
    !componentRef.current.contains(event.target as Node)
   ) {
    setIsOpen(false)
    // Reset search if there's a selected value
    if (selectedValue) {
     setInputValue(selectedValue?.template ?? '')
     setIsSearching(false)
    }
   }
  }

  document.addEventListener('mousedown', handleClickOutside)
  return () => {
   document.removeEventListener('mousedown', handleClickOutside)
  }
 }, [selectedValue])

 const toggleFolder = (folderId: number): void => {
  const newClosed = new Set(closedFolders)
  if (newClosed.has(folderId)) {
   newClosed.delete(folderId)
  } else {
   newClosed.add(folderId)
  }
  setClosedFolders(newClosed)
 }

 const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
  const { scrollTop, offsetHeight, scrollHeight } = e.currentTarget
  if (scrollTop + offsetHeight >= scrollHeight - 20) {
   if (hasMore) {
    fetchMore?.()
   }
  }
 }

 const handleTemplateSelect = (
  template: Template,
  //  folder: Folder
 ): void => {
  const newValue = {
   //    folder: folder.name,
   template: template.name,
   value: template.id,
  }
  setSelectedValue(newValue)
  setInputValue(template.name)
  setIsSearching(false)
  setIsOpen(false)
  onChange?.(template?.id)
  //   onSelect(template, folder)
 }

 const handleInputFocus = (): void => {
  setIsOpen(true)
 }

 const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
  const { value: inpValue } = e.target
  setInputValue(inpValue)
  setSearchKey(inpValue)
  setIsSearching(true)
  setIsOpen(true)
 }

 // Handle input click when there's a selected value
 const handleInputClick = (): void => {
  if (selectedValue && !isOpen) {
   setIsOpen(true)
   setIsSearching(false) // Don't filter when just opening the dropdown
  }
 }

 // Handle keyboard events
 const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  if (e.key === 'Escape') {
   setIsOpen(false)
   if (selectedValue) {
    setInputValue(selectedValue?.template ?? '')
    setIsSearching(false)
   }
  } else if (e.key === 'Backspace' && !isSearching && selectedValue) {
   // Start searching mode when user starts deleting the selected value
   setIsSearching(true)
  }
 }

 return (
  <div className="hierarchical-select" ref={componentRef}>
   <div>
    <Input
     className={
      showButtons
       ? selectedValue?.value
         ? 'hierarchical-select__input--has-edit'
         : 'hierarchical-select__input--has-button'
       : ''
     }
     ref={inputRef}
     placeholder="Search templates..."
     onChange={handleInputChange}
     onFocus={handleInputFocus}
     onClick={handleInputClick}
     onKeyDown={handleKeyDown}
     value={inputValue}
     allowClear={{
      clearIcon: (
       <i
        className="fas fa-times-circle"
        onClick={() => {
         setSelectedValue(null)
        }}
       />
      ),
     }}
    />
    {showButtons && (
     <>
      {selectedValue?.value && (
       <BaseButton
        tooltipText="Edit Template"
        type="link"
        onClick={() =>
         setShowModal({
          id: selectedValue?.value as any,
          mode: 'EDIT',
          show: true,
         })
        }
        icon={<i className="fas fa-edit" />}
       />
      )}
      <BaseButton
       tooltipText="Add Template"
       type="link"
       onClick={() => setShowModal({ id: null, mode: 'ADD', show: true })}
       icon={<i className="fas fa-plus" />}
      />
     </>
    )}
   </div>
   {isOpen && (
    <div className="hierarchical-select__dropdown-wrapper">
     <div
      className="hierarchical-select__dropdown"
      style={{
       width: showButtons
        ? selectedValue?.value
          ? 'calc(100% - 68px)'
          : 'calc(100% - 32px)'
        : '100%',
      }}
      onScroll={handleScroll}
     >
      {flatData?.map((folder) => (
       <div key={folder.id} style={{ borderBottom: '1px solid #dcdcdc' }}>
        <div
         className="hierarchical-select__folder-header"
         onClick={() => toggleFolder(folder.id)}
        >
         {closedFolders.has(folder.id) ? (
          <i className="fas fa-caret-right me-2" />
         ) : (
          <i className="fas fa-caret-down me-2" />
         )}
         <span className="hierarchical-select__folder-name">{folder.name}</span>
        </div>

        {!closedFolders.has(folder.id) && folder.templates && (
         <div>
          {folder.templates.map((template) => (
           <div
            key={template.id}
            className={`hierarchical-select__template ${
             selectedValue?.value === template.id
              ? 'hierarchical-select__template--selected'
              : ''
            }`}
            onClick={() =>
             handleTemplateSelect(
              template,
              //    folder
             )
            }
           >
            {template.name}
           </div>
          ))}
         </div>
        )}
       </div>
      ))}

      {(hasMore || isLoading) && (
       <div className="hierarchical-select__loading">
        <Spin size="small" className="animate-spin" /> Loading{' '}
        {hasMore ? 'more...' : ''}
       </div>
      )}

      {!isLoading && flatData?.length === 0 && (
       <div className="hierarchical-select__empty">No templates found</div>
      )}
     </div>
     <div style={{ height: '40px' }} />
    </div>
   )}
   {showButtons && (
    <>
     {showModal?.show && type === 'Email' && (
      <EmailTemplateModal
       //    editId={showModal?.id}
       {...(showModal?.mode === 'EDIT'
        ? {
           editId: showModal?.id,
           //  folderId: selectedValue?.value,
           //  folderName: selectedValue?.template,
          }
        : {})}
       mode={showModal?.mode ?? 'ADD'}
       onCancel={() => setShowModal({ show: false, id: null, mode: null })}
       visible
       onSuccess={(newData) => {
        setSelectedValue((prv) => ({
         template: newData?.name,
         value: newData?.id,
        }))
        setInputValue(newData?.name ?? '')
        onChange?.(newData?.id)
       }}
      />
     )}
     {showModal?.show && type === 'SMS' && (
      <TextTemplateModal
       //    editId={showModal?.id}
       {...(showModal?.mode === 'EDIT'
        ? {
           editId: showModal?.id,
           //  folderId: selectedValue?.value,
           //  folderName: selectedValue?.template,
          }
        : {})}
       mode={showModal?.mode ?? 'ADD'}
       onCancel={() => setShowModal({ show: false, id: null, mode: null })}
       visible
       onSuccess={(newData) => {
        setSelectedValue((prv) => ({
         template: newData?.name,
         value: newData?.id,
        }))
        setInputValue(newData?.name ?? '')
        onChange?.(newData?.id)
       }}
      />
     )}
    </>
   )}
  </div>
 )
}

export default TemplateTreeSelect
