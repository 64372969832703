import { Avatar, Dropdown, List, Menu } from 'antd'
import {
 useDeletenotificationMutation,
 useReadNotificationMutation,
} from 'api/baseApi'
import { BtnAnch } from 'components/elements/global'
import { queryClient } from 'lib/react-query'
import moment from 'moment'
import { useGlobal } from 'raducer/global'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { TGetNotification } from 'types'
import { formatDate } from 'utils/functions'
import Utils from 'utils/helper'

const NotificationItem: FC<{
 data?: TGetNotification
 onCancel?: () => void
}> = ({ data, onCancel }) => {
 const noti = data?.data

 const navigate = useNavigate()

 const handleDeleteNotifn = async (id?: any[]) => {
  await deleteNotification({ id }).unwrap()
  queryClient.invalidateQueries('all-notifications')
 }

 const { updateNotificationCount } = useGlobal()
 const menu = (
  <Menu>
   <Menu.Item key="delete" onClick={() => handleDeleteNotifn([data?.id])}>
    Delete
   </Menu.Item>
  </Menu>
 )
 const [showDot, setShowDot] = useState(data?.read_at)
 const [readNotification] = useReadNotificationMutation()
 const [deleteNotification] = useDeletenotificationMutation()

 const HandleUnreadNotification = async () => {
  if (data?.read_at === null) {
   await readNotification({ id: data.id }).unwrap()
   updateNotificationCount?.()
   setShowDot?.(false)
   queryClient.invalidateQueries('all-notifications')
  }
 }

 const HandleClickRedirection = () => {
  HandleUnreadNotification?.()
  if (
   noti?.notificationFor === 'Action Plan' ||
   noti?.notificationFor === 'Appointment' ||
   noti?.notificationFor === 'Task' ||
   noti?.notificationFor === 'Birthday' ||
   noti?.notificationFor === 'Enquiry' ||
   noti?.notificationFor === 'Lead transfer' ||
   noti?.notificationFor === 'Lead'
  ) {
   navigate(`#lead-details/${noti.lead_id}/profile`)
   onCancel?.()
  } else if (noti?.notificationFor === 'Action Plan List') {
   navigate('/admin/action-plan')
   onCancel?.()
  } else if (
   ['Tag', 'Stage', 'Source']?.includes(noti?.notificationFor ?? '')
  ) {
   navigate(`/admin/settings/${noti?.notificationFor?.toLowerCase()}s`)
   onCancel?.()
  } else if (noti?.notificationFor === 'lead_import') {
   navigate('/admin/leads/all-leads')
   onCancel?.()
  } else if (noti?.notificationFor === 'Email reply') {
   navigate(`#lead-details/${noti.lead_id}/timeline/emaillog`)
   onCancel?.()
  } else if (noti?.notificationFor === 'Sms reply') {
   navigate(`/admin/inbox/sms/inbox/${noti.lead_id}`)
   onCancel?.()
  } else if (noti?.notificationFor === 'My Brokerage') {
   navigate('/admin/my-brokerage')
   onCancel?.()
  } else if (noti?.notificationFor === 'My Realtor') {
   navigate('/admin/realtors')
   onCancel?.()
  }
  setShowDot(false)
 }
 const colorArray = [
  'greenColor',
  'reColor',
  'orangeColor',
  'pinkColor',
  'violotColor',
 ]

 return (
  <div>
   <List.Item
    actions={[
     showDot === null && (
      <BtnAnch
       onClick={() => {
        setShowDot(false)
        HandleUnreadNotification?.()
       }}
      >
       <small className="primary-color">
        <i className="fas fa-circle" />
       </small>
      </BtnAnch>
     ),
     <Dropdown
      overlay={menu}
      key={12}
      placement="bottomRight"
      className="notn-dlt-drpdn"
     >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
       <i className="fas fa-ellipsis-v" />
      </a>
     </Dropdown>,
     // eslint-disable-next-line react/jsx-key
    ]}
   >
    <List.Item.Meta
     avatar={
      <BtnAnch onClick={HandleClickRedirection}>
       <Avatar
        size={40}
        icon={Utils.shortName(noti?.title ?? 'No')}
        style={{
         fontSize: '1rem',
        }}
        className={colorArray[Math.floor(Math.random() * 5)]}
       />
      </BtnAnch>
     }
     title={
      <BtnAnch
       onClick={() => HandleClickRedirection?.()}
       style={{ fontWeight: 600 }}
      >
       {noti?.title}
      </BtnAnch>
     }
     description={
      <>
       <div
        style={{ fontWeight: '400', cursor: 'pointer' }}
        className="primary-color"
        onClick={HandleClickRedirection}
       >
        {noti?.body}
       </div>
       {/* <div>{moment(data?.created_at).fromNow()}</div> */}
       <div>{formatDate(data?.created_at)}</div>
      </>
     }
    />
   </List.Item>
  </div>
 )
}

export default NotificationItem
