import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import { Space } from 'antd'
import useAppControl from 'hooks/useAppControl'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import ScheduleModal from 'components/shared/Modals/ScheduleModal'
import { useDispatch } from 'react-redux'
import {
 inboxApi,
 useDeleteScheduleEmailMutation,
 useDeleteScheduledSmsMutation,
 useLazyGetScheduleEmailByIdQuery,
 useLazyPreviewScheduleSmsQuery,
 useRescheduleEmailMutation,
 useRescheduleSmsMutation,
 useScheduleEmailNowMutation,
 useSchedulesmsNowMutation,
} from '../api'
import { TInboxItem } from '../types'

const SchduleMsgActions: FC<{
 data?: TInboxItem
 refetchList?: () => void
 refetchListKey?: string
}> = ({ data, refetchList, refetchListKey = '' }) => {
 const params = useParams()
 const navigate = useNavigate()
 const d = useDispatch()

 const type = params?.type
 const mode = params?.mode

 const [deleteEmail] = useDeleteScheduleEmailMutation()
 const [deleteSms] = useDeleteScheduledSmsMutation()
 const [scheduleEmailNow] = useScheduleEmailNowMutation()
 const [schedulesmsnow, { isLoading: schedulesmsloading }] =
  useSchedulesmsNowMutation()
 const [rescheduleEmail, { isLoading: rescheduleEmailLoading }] =
  useRescheduleEmailMutation()
 const [rescheduleSms, { isLoading: rescheduleSmsLoading }] =
  useRescheduleSmsMutation()
 const { updateSendEmailWidget, updateSendSMSWidget } = useAppControl()
 const [
  getScheduleMailPreview,
  { isLoading: getMailPreviewLoading, isFetching: getMailPreviewFetching },
 ] = useLazyGetScheduleEmailByIdQuery()
 const [
  getScheduleSMSPreview,
  { isLoading: getSMSPreviewLoading, isFetching: getSMSPreviewFetching },
 ] = useLazyPreviewScheduleSmsQuery()

 const [defaultTime, setDefaultTime] = useState<string | null>(null)
 const [scheduleModal, setScheduleModal] = useState(false)
 const [Id, setId] = useState<number | undefined>()
 const [editLoading, setEditLoading] = useState(false)

 const handleDelete = (id?: any): void => {
  globalConfirm({
   content: 'Are you sure want to delete this item?',
   onOk: async () => {
    if (mode === 'sms') {
     await deleteSms({ id: [id] }).unwrap()
    } else {
     await deleteEmail({ id: [id] }).unwrap()
    }
    refetchList?.()
    navigate(-1)
   },
  })
 }

 const handleReschedule = async (
  time?: string,
  date?: string,
 ): Promise<void> => {
  if (mode === 'sms') {
   await rescheduleSms({ id: Id, date, time }).unwrap()
  } else {
   await rescheduleEmail({ id: Id, date, time }).unwrap()
  }
  setScheduleModal(false)
  setDefaultTime(null)
  refetchList?.()
 }

 const handleScheduleEmailnNow = (id?: any): void => {
  globalConfirm({
   content: `Are you sure you want to send the ${
    mode === 'sms' ? 'SMS' : 'email'
   } now?`,
   onOk: async () => {
    if (mode === 'sms') {
     await schedulesmsnow({ id: [id] }).unwrap()
    } else {
     await scheduleEmailNow({ id: [id] }).unwrap()
    }
    refetchList?.()
    navigate(-1)
   },
  })
 }

 const handleEdit = async (id?: number): Promise<void> => {
  globalConfirm({
   content: 'Are you sure you want to edit this?',
   onOk: async () => {
    if (mode === 'sms') {
     const { data: dataset } = await getScheduleSMSPreview({ id })
     setEditLoading(false)
     if (dataset) {
      updateSendSMSWidget({
       widgetFrom: 'lead',
       show: true,
       to: dataset?.number ?? [],
       editData: {
        body: dataset?.body,
        date: dataset?.date,
        time: dataset?.time,
        id: dataset?.id,
        temp_id: dataset?.temp_id,
        to: dataset?.number ?? [],
       },
       mode: 'EDIT-SHEDULE-SMS',
       inboxRefetchKey: refetchListKey,
      })
     }
    } else {
     const { data: dataset } = await getScheduleMailPreview({ id })
     setEditLoading(false)
     if (dataset) {
      updateSendEmailWidget({
       to: dataset?.email_to ?? [],
       widgetFrom: 'lead',
       show: true,
       editData: {
        body: dataset?.body,
        subject: dataset?.subject,
        to: dataset?.email_to,
        date: dataset?.date,
        time: dataset?.time,
        attachment: dataset?.attachment,
        id: dataset?.id,
        cc_email: dataset?.cc_email ?? [],
       },
       hideAttachment: dataset?.scheduled_from === 'listing',
       mode: 'EDIT-SHEDULE-MAIL',
       inboxRefetchKey: refetchListKey,
      })
     }
    }
   },
  })
 }

 return (
  <div>
   <Space className="mail-item-action mt-2">
    <BaseButton
     loading={schedulesmsloading}
     buttonStyle="outline"
     buttonType="secondary"
     size="small"
     onClick={() => handleScheduleEmailnNow(data?.id)}
    >
     Send Now
    </BaseButton>
    <BaseButton
     //  loading={
     //   editLoading &&
     //   (getMailPreviewLoading ||
     //    getMailPreviewFetching ||
     //    getSMSPreviewFetching ||
     //    getSMSPreviewLoading)
     //  }
     buttonStyle="outline"
     buttonType="gamma"
     size="small"
     onClick={(e) => {
      setEditLoading(true)
      // e.stopPropagation()
      handleEdit(data?.id)
     }}
    >
     Edit
    </BaseButton>
    <BaseButton
     buttonStyle="outline"
     buttonType="info"
     size="small"
     onClick={() => {
      setScheduleModal(true)
      setId(data?.id)
      setDefaultTime(`${data?.date} ${data?.time}`)
     }}
    >
     Re-Schedule
    </BaseButton>

    <BaseButton
     onClick={() => handleDelete(data?.id)}
     buttonStyle="outline"
     buttonType="danger"
     size="small"
    >
     Delete
    </BaseButton>
   </Space>
   {scheduleModal && (
    <ScheduleModal
     visible={scheduleModal}
     onCancel={() => {
      setScheduleModal(false)
      setDefaultTime(null)
     }}
     type={mode === 'sms' ? 'SMS' : 'EMAIL'}
     isLoading={rescheduleEmailLoading || rescheduleSmsLoading}
     onSubmitSchedule={(date, time) => handleReschedule(date, time)}
     defaultValue={defaultTime}
    />
   )}
  </div>
 )
}

export default SchduleMsgActions
