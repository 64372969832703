import { ColumnDef } from '@tanstack/react-table'
import { Checkbox, Select, Dropdown, Space, Menu, Button, Tooltip } from 'antd'
import { ReactNode } from 'react'
import { MDataTableProps, TSelectAllDropdown } from './type'

type MTRowSelectionProps = {
 onUnSelect: (id?: any) => void
 onSelectAll?: (s: boolean) => void
 onSelectRow?: MDataTableProps<any, any>['onSelectRow']
 selectAllCustomNode?: ReactNode
 handleCellSelection?: (v: any) => boolean
 getSelectionTooltip?: (v: any) => string | null
 setIsDisabled?: (v: any) => boolean
}

const MTRowSelection = ({
 onSelectAll,
 onSelectRow,
 selectAllCustomNode,
 handleCellSelection,
 getSelectionTooltip,
 setIsDisabled,
}: MTRowSelectionProps): ColumnDef<any> => ({
 id: 'selection',
 //  size: selectAllCustomNode ? 115 : 40,
 //  size: selectAllCustomNode ? 45 : 40,
 size: 45,
 enableResizing: false,
 accessorKey: 'id',
 header: ({ table }) =>
  selectAllCustomNode ?? (
   <Checkbox
    {...{
     checked: table.getIsAllRowsSelected(),
     indeterminate: table.getIsAllRowsSelected()
      ? false
      : table.getIsSomeRowsSelected(),
     onChange: (e) => {
      table.getToggleAllRowsSelectedHandler()(e)
      onSelectAll?.(e.target.checked)
     },
    }}
   />
  ),

 cell: ({ row }) => (
  <Tooltip
   {...(getSelectionTooltip?.(row.original)
    ? { title: getSelectionTooltip?.(row.original) }
    : {})}
  >
   <Checkbox
    {...(setIsDisabled?.(row.original)
     ? {
        disabled: true,
       }
     : {})}
    {...{
     checked: row.getIsSelected(),
     indeterminate: row.getIsSomeSelected(),
     ...(handleCellSelection
      ? {
         onChange: (e) => {
          if (handleCellSelection(row.original)) {
           row.getToggleSelectedHandler()(e)
           onSelectRow?.(row.original, e.target.checked)
          }
         },
        }
      : {
         onChange: (e) => {
          row.getToggleSelectedHandler()(e)
          onSelectRow?.(row.original, e.target.checked)
         },
        }),
    }}
   />
  </Tooltip>
 ),
})

export default MTRowSelection
