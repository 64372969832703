import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import TSSFormModal from 'features/settings/components/TSSFormModal'
import { FC, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/functions'
import ActionBlkBtn from 'components/elements/ActionBlock/ActionBlkBtn'
import { SVGMergeIcon } from 'components/icons'
import MergeTSSModal from './MergeTSSModal'

const TSSActionBlock: FC<{
 type: 'tag' | 'stage' | 'source'
 selectedRows?: any[]
 selectedKeys?: any[]
 refetch?: () => void
}> = ({ type, selectedRows, selectedKeys, refetch }) => {
 const [showModal, setShowModal] = useState<'add' | 'merge' | null>(null)

 return (
  <>
   <Space>
    {/* <BaseButton
     tooltipText={`Add ${capitalizeFirstLetter(type)}`}
     onClick={() => setShowModal('add')}
     buttonType="gamma"
     icon={<i className="far fa-plus" />}
     shape="circle"
    /> */}

    <ActionBlkBtn
     color="gamma"
     onClick={() => setShowModal('add')}
     icon={<i className="far fa-plus" />}
     text="Add New"
    />

    <ActionBlkBtn
     color="alpha"
     onClick={() => setShowModal('merge')}
     //  icon={<i className="far fa-object-ungroup" />}
     icon={<SVGMergeIcon />}
     text={`Merge ${capitalizeFirstLetter(type)}s`}
     {...((selectedKeys?.length ?? 0) < 2 || (selectedKeys?.length ?? 0) > 10
      ? {
         disabled:
          (selectedKeys?.length ?? 0) < 2 || (selectedKeys?.length ?? 0) > 10,
         tooltipText: `Merge ${capitalizeFirstLetter(type)}s: Min 2 and Max 10`,
        }
      : {})}
     //  disabled={
     //   (selectedKeys?.length ?? 0) < 2 || (selectedKeys?.length ?? 0) > 10
     //  }
    />
    {/* <BaseButton
     tooltipText={`Merge ${capitalizeFirstLetter(type)}`}
     onClick={() => setShowModal('merge')}
     buttonType="alpha"
     shape="circle"
     icon={<i className="far fa-object-ungroup" />}
     disabled={
      (selectedKeys?.length ?? 0) < 2 || (selectedKeys?.length ?? 0) > 10
     }
    /> */}
   </Space>

   {showModal === 'add' && (
    <TSSFormModal
     mode="ADD"
     type={type}
     onCancel={() => setShowModal(null)}
     visible
     onSuccess={refetch}
    />
   )}
   {showModal === 'merge' && (
    <MergeTSSModal
     type={type}
     onCancel={() => setShowModal(null)}
     selectedItems={selectedRows ?? []}
     refetch={refetch}
    />
   )}
  </>
 )
}

export default TSSActionBlock
