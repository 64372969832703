import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import Integration from 'features/integration/routes/Integration'
import Signature from './signature'
import TagStageSource from './tags-stages-sources'
import ResetPassword from './reset-password'
import ListingSettings from './listing-settings'

export const SettingsRoute: FC = () => {
 return (
  <Routes>
   <Route path="" element={<Navigate to="/admin/settings/tags" replace />} />
   <Route path="tags" element={<TagStageSource type="tag" />} />
   <Route path="stages" element={<TagStageSource type="stage" />} />
   <Route path="sources" element={<TagStageSource type="source" />} />
   <Route path="signature/*" element={<Signature />} />
   <Route path="reset-password" element={<ResetPassword />} />
   <Route path="integration" element={<Integration />} />
   <Route path="listing-settings" element={<ListingSettings />} />
  </Routes>
 )
}
