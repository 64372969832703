import { Input, Avatar, Tag, InputRef } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useClickAway, useKey } from 'react-use'
import { utils } from 'utils'
import { globalToaster } from 'components/elements/global'

const SWCCInput: FC<{
 value?: string[]
 onChange?: (v?: string[]) => void
 toValue?: string
}> = ({ value, onChange, toValue }) => {
 const ccBarRef = useRef(null)
 const [selectedValues, setSelectedValues] = useState<string[]>([])
 const [keyword, setKeyword] = useState('')
 const [showSuggestion, setShowSuggestion] = useState(false)
 const [selectedItem, setSelectedItem] = useState<string | null>(null)

 const inputRef = useRef<InputRef>(null)

 useEffect(() => {
  setShowSuggestion(utils.isValidEmail(keyword))
 }, [keyword])

 useClickAway(ccBarRef, () => {
  setShowSuggestion(false)
 })

 useEffect(() => {
  if (value) {
   setSelectedValues(value)
  }
 }, [value])

 const selectedItemRef = useRef<Record<any, any>>({})

 useEffect(() => {
  selectedItemRef.current = {
   selectedItem,
   showSuggestion,
   selectedValues,
   keyword,
   toValue,
  }
 }, [selectedItem, showSuggestion, selectedValues, keyword, toValue])

 const onPressEnterKey = (): void => {
  if (
   selectedItemRef?.current?.showSuggestion &&
   !selectedItemRef?.current?.selectedValues?.includes(
    selectedItemRef?.current?.keyword,
   )
  ) {
   if (
    selectedItemRef?.current?.toValue &&
    selectedItemRef?.current?.toValue === selectedItemRef?.current?.keyword
   ) {
    globalToaster({
     type: 'error',
     content:
      'To and Cc emails can not be the same ! Please add a different Email address',
    })
   } else {
    setSelectedValues((prv) => [...prv, selectedItemRef?.current?.keyword])
    setKeyword('')
    onChange?.([
     ...(selectedItemRef?.current?.selectedValues ?? []),
     selectedItemRef?.current?.keyword,
    ])
   }
  }
 }

 const onPressDelete = (): void => {
  const filteredItems = selectedItemRef?.current?.selectedValues?.filter(
   (m: any) => m !== selectedItemRef?.current?.selectedItem,
  )
  if (selectedItemRef?.current?.selectedItem) {
   setSelectedValues((prv) =>
    prv.filter((m) => m !== selectedItemRef?.current?.selectedItem),
   )
   onChange?.(filteredItems)
   setSelectedItem(filteredItems?.length > 0 ? filteredItems?.at(-1) : null)
  }
 }

 const onPressArrows = (e: KeyboardEvent): void => {
  if (selectedItemRef?.current?.selectedItem) {
   if (e?.key === 'ArrowLeft' || e?.key === 'ArrowRight') {
    const index = selectedItemRef?.current?.selectedValues?.findIndex(
     (v: any) => v === selectedItemRef?.current?.selectedItem,
    )
    if (e?.key === 'ArrowLeft') {
     if (index !== 0) {
      setSelectedItem(selectedItemRef?.current?.selectedValues?.[index - 1])
     }
    } else if (e?.key === 'ArrowRight') {
     if (index + 1 !== selectedItemRef?.current?.selectedValues?.length) {
      setSelectedItem(selectedItemRef?.current?.selectedValues?.[index + 1])
     }
    }
   }
  }
 }

 useKey('Delete', onPressDelete)
 useKey('ArrowLeft', onPressArrows)
 useKey('ArrowRight', onPressArrows)
 useKey('Enter', onPressEnterKey)

 return (
  <div
   className="sw-to-bar"
   style={{ paddingRight: '0', cursor: 'text' }}
   ref={ccBarRef}
   onClick={() => {
    if (inputRef?.current) {
     inputRef?.current?.focus()
     setSelectedItem(null)
    }
   }}
  >
   <div className="input-to-area py-0">
    <div className="sw-bar-container">
     <div>
      <div className="sw-cc-container">
       <div className="d-flex flex-wrap">
        {selectedValues?.map((i, n) => (
         <div key={n} onClick={(e) => e.stopPropagation()}>
          <Tag
           onClick={() => {
            setSelectedItem(i)
           }}
           onClose={(e) => {
            e.stopPropagation()
            setSelectedValues((prv) => prv.filter((m) => m !== i))
            onChange?.(selectedValues?.filter((m) => m !== i))
           }}
           closable
           className={`selected-item ${
            selectedItem === i ? 'active-item' : ''
           }`}
          >
           {i}
          </Tag>
         </div>
        ))}

        <div className="sw-cc-input">
         <Input
          onChange={({ target }) => setKeyword(target?.value)}
          onFocus={() => {
           setSelectedItem(null)
           if (utils.isValidEmail(keyword)) {
            setShowSuggestion(true)
           }
          }}
          value={keyword}
          ref={inputRef}
         />
        </div>
       </div>
       <div>
        {showSuggestion && !selectedValues?.includes(keyword) && (
         <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="sw-cc-suggestion"
          onClick={() => {
           if (toValue && toValue === keyword) {
            //
            globalToaster({
             type: 'error',
             content:
              'To and Cc emails can not be the same ! Please add a different Email address',
            })
           } else {
            setSelectedValues((prv) => [...prv, keyword])
            setKeyword('')
            onChange?.([...selectedValues, keyword])
           }
          }}
         >
          <div className="d-flex align-items-center">
           <Avatar size="large" icon={<i className="fas fa-user" />} />
           <span className="ms-2">{keyword}</span>
          </div>
         </motion.div>
        )}
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 )
}

export default SWCCInput
