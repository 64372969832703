import { Col, Form, Input, Alert, Select, Row } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import Modal from 'antd/lib/modal/Modal'
import React, { FC, useEffect, useState } from 'react'
import {
 TListTagStageSource,
 useAddGroupMutation,
 useGetGroupsQuery,
} from 'features/settings'
import { BaseButton } from 'components/elements/BaseButton'
import { LoadMoreSelect } from 'components/elements/Form'
import useFetchPage2 from 'hooks/useFetchPage2'
import { useAddLeadMutation } from '../api'
import { TStoreLead } from '../types'

const formInt: TStoreLead = {
 email: '',
 name: '',
 phone: '',
 country_code: '',
 source: 'Direct',
}

const LeadForm: FC<{
 visible?: boolean
 onCancel?: () => void
}> = ({ onCancel, visible }) => {
 const [addLead, { isLoading: addLeadLoading }] = useAddLeadMutation()
 const [addGroup, { isLoading: addGroupLoading }] = useAddGroupMutation()

 const [searchKey, setSearchKey] = useState('')

 const {
  isLoading,
  hasMore,
  fetchMore,
  // refetch,
  flatData,
  isRefetching,
  // meta,
 } = useFetchPage2<TListTagStageSource>('get-groups-name', {
  method: 'get',
  params: `type=source&search=${searchKey}`,
 })

 const [form] = Form.useForm<TStoreLead>()
 const [addSourceForm] = Form.useForm<{ name: string }>()
 const emailField = Form.useWatch('email', form)
 const phoneField = Form.useWatch('phone', form)
 //  const { data } = useGetGroupsQuery({ type: ['source'] })
 const [addNewSource, setAddNewSource] = useState(false)
 const [sources, setSources] = useState(flatData)

 useEffect(() => {
  setSources(flatData)
 }, [flatData])

 const onSubmit = async () => {
  const values = await form.validateFields()
  await addLead({ ...values, country_code: 1 }).unwrap()
  onCancel?.()
 }

 useEffect(
  () => () => {
   form.resetFields()
  },
  [],
 )
 const handleAddSource = async (): Promise<void> => {
  const values = await addSourceForm.validateFields()
  await addGroup({ ...values, type: 'source' }).unwrap()
  setSources((prv) => [{ name: values.name } as any, ...(prv ?? [])])
  // setSources((prv) => ({
  //  ...prv,
  //  source: [...(prv?.source ?? []), { name: values.name }],
  // }))
  form.setFieldValue('source', values.name)
  addSourceForm?.resetFields()
  setAddNewSource(false)
 }

 return (
  <Modal
   confirmLoading={addLeadLoading}
   onOk={onSubmit}
   okText="Add Lead"
   title="Add Lead"
   onCancel={onCancel}
   visible={visible}
   okButtonProps={{ disabled: emailField === '' && phoneField === '' }}
  >
   <Form initialValues={formInt} form={form} layout="vertical">
    <Form.Item
     rules={[{ required: true, message: 'Lead name is required' }]}
     name="name"
     label="Full name"
    >
     <Input placeholder="Enter Full name" />
    </Form.Item>

    <Form.Item
     rules={[
      //   { required: true, message: 'Phone is required' },
      { min: 11, message: 'Please enter a valid phone no' },
     ]}
     getValueFromEvent={(e, a) =>
      e.unmaskedValue ? `1${e.unmaskedValue}` : ''
     }
     name="phone"
     label="Phone number"
    >
     <MaskedInput mask="+1(000) 000-0000" />
     {/* <InputPhoneNumber placeholder="" /> */}
    </Form.Item>
    <Form.Item
     name="email"
     label="Email"
     rules={[{ type: 'email', message: 'Please enter a valid email' }]}
    >
     <Input placeholder="Enter Email" type="email" />
    </Form.Item>
    <Row>
     <Col span={22}>
      <Form.Item name="source" label="Source">
       {/* <Select
        placeholder="Select Source"
        options={sources?.source?.map((v) => ({
         label: v?.name,
         value: v?.name,
        }))}
        filterOption={(input, option) =>
         option?.label
          ? option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : false
        }
       /> */}
       <LoadMoreSelect
        // value={selectedRealtor}
        // onChange={(val) => setRealtor(val)}
        data={sources?.map((v) => ({ value: v?.name, label: v?.name }))}
        onSearch={setSearchKey}
        placeholder="Select Source"
        hasMore={hasMore}
        fetchMore={fetchMore}
        loading={isLoading || isRefetching}
       />
      </Form.Item>
     </Col>
     <Col span={2} className="m-auto">
      <BaseButton
       tooltipText="Add New"
       onClick={() => setAddNewSource(true)}
       buttonType="gamma"
       icon={<i className="far fa-plus" />}
       customClass="ms-2"
       style={{ marginTop: '5px' }}
      />
     </Col>
    </Row>
   </Form>
   {emailField === '' && phoneField === '' && (
    <Alert
     message="Please provide either email or phone number"
     type="warning"
     showIcon
    />
   )}
   {addNewSource && (
    <Modal
     confirmLoading={addGroupLoading}
     onOk={handleAddSource}
     okText="Submit"
     onCancel={() => {
      setAddNewSource(false)
      addSourceForm?.resetFields()
     }}
     visible={visible}
     title="Add New Source"
    >
     <Form form={addSourceForm} initialValues={{ name: '' }} layout="vertical">
      <Form.Item
       name="name"
       rules={[{ required: true, message: 'Source name is Required' }]}
       label="Source name"
      >
       <Input placeholder="Enter Source name" />
      </Form.Item>
     </Form>
    </Modal>
   )}
  </Modal>
 )
}

export default LeadForm
