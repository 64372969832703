import { Editor } from '@tinymce/tinymce-react'
import { Modal, Spin } from 'antd'
import TemplateTreeSelect from 'features/automation/components/action-plan/TemplateTreeSelect'
import { useUploadTinyFileMutation } from 'features/settings'
import { useGlobal } from 'raducer/global'
import React, { FC, useEffect, useRef, useState } from 'react'
import SignaturesModal from './SignaturesModal'

type EMailTextEditorProps = {
 value?: string
 onChange?: (val: any) => void
 mergfield?: boolean
 emailTemplate?: boolean
 onSelectTemplate?: (id?: string) => void
 precondisable?: boolean
 onChangeSignatue?: (v: string) => void
 showSignatureBtn?: boolean
 selectedSign?: number
 setSelectedSign?: React.Dispatch<React.SetStateAction<number | undefined>>
}

const plugin = [
 'pagebreak',
 'searchreplace',
 'visualblocks',
 'visualchars',
 'insertdatetime',
 'nonbreaking',
 'table',

 'template',
 'paste',
 'help',

 'fullpage',
 'image',
 'code',
 'link',
 'lists advlist',
 'charmap',
 'hr',
]

const InternalTextEditor: React.ForwardRefRenderFunction<
 Editor,
 EMailTextEditorProps
> = (
 {
  onChange,
  value,
  mergfield,
  emailTemplate,
  onSelectTemplate,
  precondisable,
  onChangeSignatue,
  showSignatureBtn = false,
  selectedSign,
  setSelectedSign,
 },
 ref,
) => {
 const editorRef = useRef<any>(null)
 const toolbar = `undo redo | mergfield | templates ${
  showSignatureBtn ? '| changeSignature ' : ''
 }| bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | fullscreen  preview save print code image fullpage link | casechange permanentpen formatpainter removeformat | pagebreak | charmap insertfile  pageembed   anchor codesample | a11ycheck ltr rtl | showcomments addcomment | inserttable`
 const [isModalOpen, setIsModalOpen] = useState(false)

 const [uploadImg] = useUploadTinyFileMutation()
 const { global } = useGlobal()
 const [showTempModal, setShowTempModal] = useState(false)

 useEffect(() => {
  const handleClickOutside = (event: any) => {
   const toolbarPop = document.querySelector('.tox-tinymce-aux')
   const toolbarButton = document.querySelector(
    '.tox-tbtn[aria-label="More..."]',
   )

   if (toolbarPop?.hasChildNodes()) {
    const isClickOutsidePopover = !toolbarPop.contains(event.target)
    const isClickOutsideButton = !toolbarButton?.contains(event.target)

    if (isClickOutsidePopover && isClickOutsideButton) {
     return (toolbarButton as any)?.click()
    }
   }
  }

  document.addEventListener('mousedown', handleClickOutside)

  return () => {
   document.removeEventListener('mousedown', handleClickOutside)
  }
 }, [])

 if (global.body_mergefield?.length === 0) {
  return (
   <div className="full-center">
    <Spin size="small" />
   </div>
  )
 }

 return (
  <>
   <Editor
    key="Editor-email2"
    disabled={precondisable && true}
    value={value}
    onEditorChange={onChange}
    // ref={ref}
    ref={editorRef}
    apiKey={global?.tiny_mce_key ?? ''}
    onInit={(editor) => {
     editorRef.current = editor
    }}
    init={{
     content_css: '/css/content.min.css',
     plugins: plugin,

     menubar: false,
     toolbar,
     toolbar_location: 'bottom',
     images_upload_handler: async (blobInfo, success, failure) => {
      const formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())
      try {
       const data = await uploadImg(formData).unwrap()
       success(data)
      } catch (error) {
       failure('Upload Image Error')
      }
     },
     setup: (editor) => {
      editor.ui.registry.addIcon(
       'signatureIcon',
       `<svg viewBox="0 0 640 512" width="24" height="24">
         <path
          fill="#000000"
          d="M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z"
         />
        </svg>`,
      )

      if (showSignatureBtn) {
       editor.ui.registry.addButton('changeSignature', {
        icon: 'signatureIcon',
        tooltip: 'Change Signature',
        onAction: () => setIsModalOpen(true),
       })
      }
      if (mergfield) {
       editor.ui.registry.addMenuButton('mergfield', {
        type: 'menubutton',

        tooltip: 'Merge Field',
        text: 'Merge Field',
        fetch: (callback) => {
         callback(
          global && global.body_mergefield && global.body_mergefield.length > 0
           ? global?.body_mergefield.map((v) => ({
              onAction: () =>
               editor.editorCommands.execCommand(
                'mceInsertContent',
                false,
                v.id,
               ),
              type: 'menuitem',
              value: `${v.id}`,
              text: v.name,
             }))
           : [],
         )
        },
       })
      }
      //  if (emailTemplate && data && data?.length > 0) {
      //   editor.ui.registry.addMenuButton('templates', {
      //    type: 'menubutton',
      //    tooltip: 'Templates',
      //    text: 'Templates',

      //    fetch: (callback) => {
      //     callback(
      //      data?.map((v) => ({
      //       onAction: () => {
      //        onSelectTemplate?.(v.id)
      //       },
      //       text: v.name,
      //       value: v.id,
      //       type: 'menuitem',
      //      })),
      //     )
      //    },
      //   })
      //  }
      if (emailTemplate) {
       editor.ui.registry.addButton('templates', {
        text: 'Templates',
        tooltip: 'Templates',
        icon: 'action-prev',
        //  icon: 'chevron-down',
        onAction: () => {
         setShowTempModal(true)
        },
       })
      }
     },
    }}
   />
   {isModalOpen && (
    <SignaturesModal
     visible={isModalOpen}
     onCancel={() => setIsModalOpen(false)}
     onChangeSignature={(v) => {
      onChangeSignatue?.(v)
     }}
     selectedSign={selectedSign}
     setSelectedSign={setSelectedSign}
    />
   )}
   {showTempModal && (
    <EmailTempModal
     onSubmit={(id) => {
      onSelectTemplate?.(id)
      setShowTempModal(false)
     }}
     onCancel={() => {
      setShowTempModal(false)
     }}
    />
   )}
  </>
 )
}

const EmailTextEditor = React.forwardRef<Editor, EMailTextEditorProps>(
 InternalTextEditor,
)
EmailTextEditor.defaultProps = {
 mergfield: true,
 emailTemplate: true,
 precondisable: false,
}
export default EmailTextEditor

const EmailTempModal: FC<{
 onCancel: () => void
 onSubmit: (id: any) => void
}> = ({ onCancel, onSubmit }) => {
 const [selectedTemplate, setSelectedTemp] = useState<any>(null)
 return (
  <Modal
   onOk={() => {
    onSubmit(selectedTemplate)
   }}
   okText="Ok"
   title="Select Template"
   onCancel={onCancel}
   visible
   okButtonProps={{ disabled: !selectedTemplate }}
  >
   <TemplateTreeSelect
    onChange={(e) => setSelectedTemp(e)}
    showButtons={false}
    value={selectedTemplate}
    //  templateName={templateName ?? ''}
    type="Email"
   />
  </Modal>
 )
}
