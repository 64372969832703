import { Row } from 'antd'
import { useUpdateLeadlogdataStatusMutation } from 'features/leads/api'
import { TUserActivityEmailLog } from 'features/leads/types'
import { FC, useEffect } from 'react'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import ViewAttachments from 'components/elements/ViewAttachments'
import ActivityEmailActions from './ActivityEmailActions'

const ActivityEmail: FC<{
 refetch?: () => void
 data?: TUserActivityEmailLog
 onCancel?: () => void
}> = ({ data, refetch, onCancel }) => {
 const [updateLeadlogdataStatus] = useUpdateLeadlogdataStatusMutation()
 const updateLeadLog = async (id: any) => {
  await updateLeadlogdataStatus?.({ id, type: 'email' }).unwrap()
  refetch?.()
 }
 const { isTransferred } = useLeadDetails()

 useEffect(() => {
  if (data) {
   if (data?.send_type === 'received' || data?.send_type === 'Received') {
    updateLeadLog?.(data.id)
   }
  }
 }, [data])

 return (
  <>
   {isTransferred && (
    <ActivityEmailActions onClickReOrFor={onCancel} datas={data} />
   )}
   <div className="mail-preview-activity">
    <p>From:</p>
    <div className="ms-3">
     <p className="bold">{data?.from_name}</p>
     <p className="body">{data?.from_mail}</p>
    </div>
    <p>To:</p>
    <div className="ms-3">
     <p className="bold">{data?.to_name}</p>
     <p className="body">{data?.to_mail}</p>
    </div>
    {(data?.cc_email?.length ?? 0) > 0 && (
     <>
      <p>Cc:</p>
      <div className="ms-3">
       <p className="body">
        {data?.cc_email?.map(
         (i, n) => `${i} ${n + 1 === data?.cc_email?.length ? '' : ','}  `,
        )}
       </p>
      </div>
     </>
    )}
    {data?.subject && (
     <>
      <p>Subject:</p>
      <div className="ms-3">
       <p className="body">{data?.subject}</p>
      </div>
     </>
    )}
    {data?.body !== null && data?.body !== '' && (
     <>
      <p>Message:</p>
      <div
       className="message ms-3 mb-3"
       dangerouslySetInnerHTML={{ __html: `${data?.body}` }}
       //  style={{ whiteSpace: 'pre-wrap' }}
      />
     </>
    )}
   </div>
   {(data?.attachment?.length ?? 0) > 0 && (
    <>
     <p>Attachments:</p>
     {/* <div className="attachments-wrapper">
      {data?.attachment?.map?.((v, index) => (
       <a
        target={
         ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
          v?.split('.').at(-1),
         )
          ? '_blank'
          : '_self'
        }
        href={v}
        key={index}
        rel="noreferrer"
       >
        <div className="attachment">
         <i className="fas fa-file" />
        </div>
       </a>
      ))}
     </div> */}
     <ViewAttachments attachments={data?.attachment} mode="row" />
    </>
   )}
  </>
 )
}
export default ActivityEmail
