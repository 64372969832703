import { FC, useEffect, useState } from 'react'
import { Menu, Layout, Tooltip } from 'antd'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from 'config'
import { baseAPI, useManageWebsiteMutation } from 'api/baseApi'
import { useWebSettings } from 'raducer/webSettings'
import { useAppDispatch } from 'hooks'
import { useLeadFilter } from 'features/leads/slice/leadSlice'
import {
 MyBrokerageIcon,
 SvgAlertIcon,
 SvgAutomationIcon,
 SvgCrmIcon,
 SvgNewListingIcon,
 SvgSettingsIcon,
} from 'components/icons'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import useAuth from 'hooks/useAuth'
import { useGlobal } from 'raducer/global'

const Sidebar: FC<{
 collapsed: boolean
 //  sidebarLInk: SidebarLinksType[]
}> = ({
 collapsed,
 // sidebarLInk
}) => {
 const { global } = useGlobal()
 const { user } = useAuth()
 const { pathname } = useLocation()
 const navigate = useNavigate()
 const d = useAppDispatch()
 const { onboarding_settings } = useWebSettings()
 const { onBoardListingStatus } = onboarding_settings || {}
 const { updateRecentLeadData, recentLeadData } = useLeadFilter()
 const [manageWebsiteMutation] = useManageWebsiteMutation()
 const [openKeys, setOpenKeys] = useState<string[]>([])

 const manageWebsite = () => {
  manageWebsiteMutation?.()
   .then((res: any) => {
    if (!onBoardListingStatus?.manage_website) {
     d(baseAPI.util.invalidateTags(['WEB-SETTINGS']))
    }
    if (res?.data?.url) {
     window.open(res.data.url, '_blank')
    }
   })
   .catch((err) => {
    console.log(err)
   })
 }

 //  const [openKeys, setOpenKeys] = useState([]);
 const [selectedKeys, setSelectedKeys] = useState<any>([])

 const getParentKey = (pathname: string) => {
  const sections = [
   'alert',
   'leads',
   'listing',
   'automation',
   'settings',
   'templates',
   'reports',
  ]
  const matchedSection = sections.find((section) =>
   pathname?.includes(`/${section}/`),
  )

  return matchedSection ? `${APP_PREFIX_PATH}/${matchedSection}` : pathname
 }

 useEffect(() => {
  if (pathname) {
   const parentKey = getParentKey(pathname)
   setSelectedKeys([pathname, parentKey].filter(Boolean))
   if (!collapsed) {
    setOpenKeys([parentKey])
   }
  }
 }, [pathname, collapsed])

 const { account_type } = user || {}
 const {
  user_type_sold,
  listing_plan,
  listing_key,
  brokerage_data,
  open_house,
 } = global || {}

 type SidebarLinksType = ItemType

 const SidebarLinks: SidebarLinksType[] = [
  {
   icon: <i className="fas fa-chart-line" />,
   key: `${APP_PREFIX_PATH}/dashboard`,
   label: 'Dashboard',
  },
  {
   icon: (
    <span>
     <SvgCrmIcon />
    </span>
   ),
   key: `${APP_PREFIX_PATH}/leads`,
   label: 'CRM',
  },
  ...(listing_key
   ? [
      {
       icon: (
        <span>
         <SvgNewListingIcon />
        </span>
       ),
       key: `${APP_PREFIX_PATH}/listing`,
       label: 'Listings',
       children: [
        ...(listing_plan !== 'Pre-Condo'
         ? [
            {
             label: 'Property Listings',
             key: `${APP_PREFIX_PATH}/listing/property-listing`,
            },
           ]
         : []),
        {
         label: (
          <Tooltip overlay="Pre-Construction Listings">
           Pre-Construction Listings
          </Tooltip>
         ),
         key: `${APP_PREFIX_PATH}/listing/pre-con-listing`,
        },
        ...((brokerage_data?.length ?? 0) > 0
         ? [
            {
             label: 'Brokerage Listings',
             key: `${APP_PREFIX_PATH}/listing/brokerage-listing`,
            },
           ]
         : []),
       ],
      },
     ]
   : []),
  ...(listing_plan !== 'Pre-Condo'
   ? [
      {
       icon: (
        <span>
         <SvgAlertIcon />
        </span>
       ),
       key: `${APP_PREFIX_PATH}/alert`,
       label: 'Alert',
       children: [
        {
         label: ' New Listing Alert',
         key: `${APP_PREFIX_PATH}/alert/new-listing`,
        },
        ...(user_type_sold
         ? [
            {
             label: (
              <Tooltip overlay="Neighbourhood Alert">
               Neighbourhood Alert
              </Tooltip>
             ),
             key: `${APP_PREFIX_PATH}/alert/sold-listing`,
            },
           ]
         : []),
        ...(open_house
         ? [
            {
             label: 'Open House Alert',
             key: `${APP_PREFIX_PATH}/alert/open-house-listing`,
            },
           ]
         : []),
        {
         label: 'Price Drop Alert',
         key: `${APP_PREFIX_PATH}/alert/price-drop`,
        },
       ],
      },
     ]
   : []),
  {
   icon: (
    <span>
     <SvgAutomationIcon />
    </span>
   ),
   key: `${APP_PREFIX_PATH}/automation`,
   label: 'Automation',
   children: [
    {
     label: 'Action Plan',
     key: `${APP_PREFIX_PATH}/automation/action-plan`,
    },
    {
     label: 'Market Updates',
     key: `${APP_PREFIX_PATH}/automation/market-updates`,
    },
    {
     label: (
      <Tooltip overlay="Real Estate Newsletter">Real Estate Newsletter</Tooltip>
     ),
     key: `${APP_PREFIX_PATH}/automation/real-estate-news`,
    },
   ],
  },
  ...(account_type === 'Brokerage'
   ? [
      {
       icon: <i className="fas fa-users" />,
       label: 'My Realtors',
       key: `${APP_PREFIX_PATH}/realtors`,
      },
     ]
   : []),
  ...(account_type === 'Realtor'
   ? [
      {
       icon: (
        <span>
         <MyBrokerageIcon />
        </span>
       ),
       label: 'My Brokerage',
       key: `${APP_PREFIX_PATH}/my-brokerage`,
      },
     ]
   : []),
  {
   icon: (
    <span>
     <SvgSettingsIcon />
    </span>
   ),
   key: `${APP_PREFIX_PATH}/settings`,
   label: 'Settings',
   children: [
    {
     label: 'Tags',
     key: `${APP_PREFIX_PATH}/settings/tags`,
    },
    {
     label: 'Stages',
     key: `${APP_PREFIX_PATH}/settings/stages`,
    },
    {
     label: 'Sources',
     key: `${APP_PREFIX_PATH}/settings/sources`,
    },
    {
     label: 'Signature',
     key: `${APP_PREFIX_PATH}/settings/signature`,
    },
    ...(listing_key
     ? [
        {
         label: 'Listing Settings',
         key: `${APP_PREFIX_PATH}/settings/listing-settings`,
        },
       ]
     : []),
   ],
  },
  {
   icon: <i className="fas fa-file-chart-line" />,
   key: `${APP_PREFIX_PATH}/templates`,
   label: 'Templates',
   children: [
    {
     label: 'SMS Templates',
     key: `${APP_PREFIX_PATH}/templates/text-templates`,
    },
    {
     label: 'Email Templates',
     key: `${APP_PREFIX_PATH}/templates/email-templates`,
    },
    {
     label: 'Birthday Wishes',
     key: `${APP_PREFIX_PATH}/templates/birthday-wishes`,
    },
   ],
  },
  {
   label: 'Integration',
   key: `${APP_PREFIX_PATH}/integration`,
   icon: <i className="fas fa-link" />,
  },
  {
   icon: <i className="fas fa-chart-pie" />,
   key: `${APP_PREFIX_PATH}/reports`,
   label: 'Report',
   children: [
    {
     label: 'Email Reports',
     key: `${APP_PREFIX_PATH}/reports/email-reports`,
    },
    {
     label: 'SMS Reports',
     key: `${APP_PREFIX_PATH}/reports/sms-reports`,
    },
   ],
  },
  {
   icon: <i className="fas fa-solid fa-globe" />,
   key: 'manage-website',
   label: 'Manage Website',
  },
 ]

 return (
  <Layout.Sider theme="light" className="crmSidebar" collapsed={collapsed}>
   <Link
    onClick={() => {
     if (recentLeadData) {
      updateRecentLeadData?.(null)
     }
    }}
    to={`${ADMIN_PREFIX_PATH}/dashboard`}
   >
    <div className="logo">
     <img src="/logo-sm.png" alt="" />
    </div>
   </Link>

   <Menu
    selectable
    // selectedKeys={pathname ? [pathname] : []}
    selectedKeys={selectedKeys}
    onClick={(e) => {
     if (e?.domEvent?.ctrlKey && e?.key !== 'manage-website') {
      window.open(e?.key, '_blank')
     } else if (e?.key === 'manage-website') {
      manageWebsite()
     } else if (e?.key === `${APP_PREFIX_PATH}/dashboard` && recentLeadData) {
      updateRecentLeadData?.(null)
     } else {
      navigate(e?.key)
     }
    }}
    rootClassName={`sidebar-menu ${collapsed ? 'collapsed-sidebar' : ''}`}
    theme="light"
    items={SidebarLinks}
    mode="inline"
    openKeys={openKeys}
    onOpenChange={(v) => {
     setOpenKeys([v?.at(-1) ?? ''])
    }}
   />
  </Layout.Sider>
 )
}

export default Sidebar
