import { Layout } from 'antd'
import React, { FC } from 'react'
import moment from 'moment'

const Footer: FC<{
 collapsed: boolean
}> = ({ collapsed }) => {
 const { Footer: AntFooter } = Layout

 return (
  <AntFooter
   className="crm-footer"
   style={{
    textAlign: 'center',
    left: collapsed ? '80px' : '200px',
    transition: 'left 0.2s',
   }}
  >
   Copyright © {moment().format('YYYY')}
   <div className="link">
    <a href="https://agentroof.com/" target="_blank" rel="noreferrer">
     &nbsp;Agentroof
    </a>
   </div>
   . All rights reserved.
  </AntFooter>
 )
}

export default Footer
