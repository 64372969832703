import { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { globalToaster } from 'components/elements/global'
import { API_BASE_URL } from 'config'
import { http } from 'lib/http'
import { RootState } from 'store'
import { ApiResponce } from 'types'

const getTokenFromUrl = (): string => {
 const urlParams = new URLSearchParams(window.location.search)
 return urlParams.get('token') ?? ''
}

type AxioQueryArg = {
 url: string
 method?: AxiosRequestConfig['method']
 body?: AxiosRequestConfig['data']
}
const axiosBaseQuery =
 (
  { baseUrl }: { baseUrl?: string } = { baseUrl: `${API_BASE_URL}/` },
 ): BaseQueryFn<AxioQueryArg | string, unknown, unknown> =>
 async (arg, { getState, signal }) => {
  const source = axios.CancelToken.source()
  signal.addEventListener('abort', () => {
   source.cancel()
  })
  try {
   const { token: authToken } = (getState() as RootState).auth

   const token = authToken || getTokenFromUrl()

   const { data }: AxiosResponse<ApiResponce> = await http({
    url: `${baseUrl}${typeof arg === 'string' ? arg : arg.url}`,
    method: typeof arg === 'string' ? 'get' : arg.method,
    data: typeof arg === 'string' ? '' : arg.body,
    cancelToken: source.token,
    headers: {
     Authorization: `Bearer ${token}`,
     Accept: 'application/json',
    },
   })

   if (data?.status === 'success' || data.status === true || data.success) {
    return { data }
   }
   globalToaster({ type: 'error', content: data.message })
   return { error: { status: 400, data } }
  } catch (axiosError) {
   const err = axiosError as AxiosError
   return {
    error: { status: err.response?.status, data: err.response?.data },
   }
  }
 }

export default axiosBaseQuery
