import React, { FC } from 'react'
import ActionPlanForm from '../../components/action-plan/ActionPlanForm'
import ActionFormProvider from '../../providers/ActionFormProvider'

const Add: FC<{ onCancel: () => void; refetch: () => void }> = ({
 onCancel,
 refetch,
}) => (
 <ActionFormProvider>
  <ActionPlanForm onCancel={onCancel} refetchList={refetch} />
 </ActionFormProvider>
)

export default Add
