import { Button, ButtonProps, Tooltip } from 'antd'
import React, { FC, ReactNode } from 'react'

type TActionBlkBtn = ButtonProps & {
 text: string
 icon: ReactNode
 color:
  | 'primary'
  | 'success'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'danger'
  | 'alpha'
  | 'beta'
  | 'gamma'
  | 'gray'
 tooltipText?: string
 btnClassName?: string
}

const ActionBlkBtn: FC<TActionBlkBtn> = ({
 color,
 icon,
 text,
 tooltipText,
 btnClassName = '',
 ...btnProps
}) => {
 return (
  <Tooltip title={tooltipText ?? text}>
   <Button
    {...btnProps}
    className={`action-blk-btn ${btnClassName ?? ''} ${
     btnProps?.disabled ? 'blk-btn-disabled' : ''
    }`}
   >
    {icon && <span className={`icon icon-${color}`}>{icon}</span>}
    <span className="btn-text">{text}</span>
   </Button>
  </Tooltip>
 )
}

export default ActionBlkBtn
