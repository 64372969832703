import { Form, Input, Modal } from 'antd'
import React, { FC, useEffect, useMemo } from 'react'
import { TGroupName, TMode } from 'types'
import { capitalizeFirstLetter } from 'utils/functions'
import { useAddGroupMutation, useUpdateGroupMutation } from '../api'
import { TGroupForm } from '../types'

const TSSFormModal: FC<{
 onSuccess?: () => void
 editData?: TGroupForm
 mode?: TMode
 type: TGroupName
 visible?: boolean
 onCancel?: () => void
}> = ({ type, onCancel, visible, mode = 'ADD', editData, onSuccess }) => {
 const [addGroup, { isLoading }] = useAddGroupMutation()
 const [updateGroup, { isLoading: upLoading }] = useUpdateGroupMutation()
 const title = useMemo(() => capitalizeFirstLetter(type) || '', [type])

 const [form] = Form.useForm<TGroupForm>()

 const handleSubmit = async (): Promise<void> => {
  const { name } = await form.validateFields()
  if (mode === 'EDIT') {
   await updateGroup({
    body: { name, type, color: 'red' },
    id: editData?.id,
   }).unwrap()
   onSuccess?.()
  } else {
   await addGroup({ name, type, color: 'red' }).unwrap()
   onSuccess?.()
  }
  onCancel?.()
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({ name: editData.name })
  }
  return () => {
   form.resetFields()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [editData, mode, form])

 return (
  <Modal
   onOk={handleSubmit}
   confirmLoading={isLoading || upLoading}
   okText={`${mode === 'EDIT' ? 'Update' : 'Add'} ${title}`}
   onCancel={onCancel}
   visible={visible}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} ${title}`}
  >
   <Form initialValues={{ name: '' }} form={form} layout="vertical">
    <Form.Item
     rules={[{ required: true, message: `${title} name is Required` }]}
     name="name"
     className="m-0"
     label={`${title} Name`}
    >
     <Input placeholder={`Enter ${title} Name`} />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default TSSFormModal
