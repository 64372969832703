import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import Navbar from 'components/layout-modules/Navbar/Navbar'
import { NoticationModule } from 'components/layout-modules/NoticationModule'
import { useGlobal } from 'raducer/global'
import { FCC } from 'types'
import { useCookies } from 'react-cookie'
import UserOnboardModal from 'features/user-onboard/components/UserOnboardModal'
import { useWebSettings } from 'raducer/webSettings'
import AppDownloadModal from 'features/user-onboard/components/AppDownloadModal'
import useUpdateQrPopupEvery24H from 'features/user-onboard/hooks/useUpdateQrPopupEvery24H'
import Sidebar from 'components/layout-modules/Sidebar/Sidebar'
import OverlayContextWrapper from './OverlayContextWrapper'
import Footer from './Footer'
import AdminControlPanel from './AdminControlPanel'

const { Content } = Layout

const AdminLayout: FCC = () => {
 const { global, toggleNotification } = useGlobal()
 const { onboarding_settings, app_user, userOnboardLocal } = useWebSettings()
 const { onboardShow } = onboarding_settings || {}
 const { hideQrPopup, minimizeOnBoardPopup } = userOnboardLocal || {}
 const [cookies, setCookie] = useCookies(['ar_sidebar'], {
  doNotParse: true,
  doNotUpdate: true,
 })

 useUpdateQrPopupEvery24H()

 return (
  <Layout className="admin-layout" style={{ minHeight: '100vh' }}>
   <Sidebar collapsed={cookies?.ar_sidebar === '1'} />
   <Layout className="crm-wrapper-area">
    <Navbar collapsed={cookies?.ar_sidebar === '1'} setCookie={setCookie} />
    <Content
     className="crm-content-area"
     style={{
      marginLeft: cookies?.ar_sidebar === '1' ? '80px' : '200px',
      transition: 'margin-left 0.2s',
     }}
    >
     <OverlayContextWrapper />
     <Outlet />
    </Content>
    <Footer collapsed={cookies?.ar_sidebar === '1'} />
   </Layout>
   {onboardShow?.popup_status && <UserOnboardModal />}
   {app_user &&
    (hideQrPopup === 'show-over-onboard' ||
     !hideQrPopup ||
     hideQrPopup === 'show') &&
    (!onboardShow?.popup_status ||
     (onboardShow?.popup_status && minimizeOnBoardPopup)) && (
     <AppDownloadModal />
    )}
   {global.notification && (
    <NoticationModule
     visible={global.notification}
     onCancel={() => toggleNotification()}
    />
   )}
   <AdminControlPanel />
  </Layout>
 )
}

export default AdminLayout
