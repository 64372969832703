import './App.css'
import { AppProvider } from 'providers/app'
import { AppRoutes } from 'routes'
import { ConfigProvider } from 'antd'
import styles from 'styles/var.module.scss'
import { FacebookProvider } from 'react-facebook'
import { FACEBOOK_ID, RECAPTCHA_SITE_KEY } from 'config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { CookiesProvider } from 'react-cookie'
import { FC } from 'react'

ConfigProvider.config({
 theme: {
  primaryColor: styles.primaryColor,
 },
})

const App: FC = () => {
 return (
  <ConfigProvider>
   <CookiesProvider>
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
     <AppProvider>
      <FacebookProvider version="v18.0" appId={FACEBOOK_ID}>
       <AppRoutes />
      </FacebookProvider>
     </AppProvider>
    </GoogleReCaptchaProvider>
   </CookiesProvider>
  </ConfigProvider>
 )
}

export default App
