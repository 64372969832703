import { Modal, Radio, Checkbox } from 'antd'
import React, { FC, useMemo, useState } from 'react'
import { useTssMergeMutation } from 'features/settings/api'
import { capitalizeFirstLetter } from 'utils/functions'
import { SVGWarningCircle } from 'components/icons'

const MergeTSSModal: FC<{
 onCancel: () => void
 refetch?: () => void
 type: 'tag' | 'stage' | 'source'
 selectedItems: any[]
}> = ({ onCancel, refetch, type, selectedItems }) => {
 const [tssMerge, { isLoading }] = useTssMergeMutation()
 const [mainTag, setMainTag] = useState<null | number>(null)
 const [checked, setChecked] = useState(false)
 const handleMergeLeads = async (): Promise<void> => {
  if (mainTag) {
   await tssMerge({
    parent_id: mainTag,
    merge_ids: selectedItems
     ?.map((lead) => lead?.id)
     ?.filter((id) => id !== mainTag),
    move_action: checked,
    type,
   }).unwrap()
  }
  onCancel()
  refetch?.()
 }

 const icon = useMemo(() => {
  switch (type) {
   case 'tag':
    return 'far fa-tags'
   case 'stage':
    return 'far fa-th'
   case 'source':
    return 'far fa-globe'
   default:
    return 'far fa-tags'
  }
 }, [type])

 return (
  <Modal
   confirmLoading={isLoading}
   okButtonProps={{ disabled: !mainTag }}
   onOk={handleMergeLeads}
   okText={`Yes, Merge ${selectedItems?.length ?? 0} ${capitalizeFirstLetter(
    type,
   )}s`}
   visible
   title={`Merge ${capitalizeFirstLetter(type)}s`}
   onCancel={onCancel}
  >
   <div>
    <p> Choose the main {type}:</p>
    <Radio.Group
     onChange={({ target }) => {
      setMainTag(target?.value)
     }}
     className="w-100"
     style={{ color: 'red' }}
    >
     {selectedItems?.map((item, n) => (
      <div
       key={n}
       className={`p-2 my-2 ${item?.id === mainTag ? 'border-danger' : ''}`}
       style={{ border: '1px solid #ccc' }}
      >
       <Radio value={item?.id} style={{ width: '100%' }}>
        <i className={`${icon} pe-2`} />
        {item?.name}
       </Radio>
      </div>
     ))}
    </Radio.Group>
    <div className="mt-3">
     <Checkbox
      checked={checked}
      onChange={({ target }) => setChecked(target?.checked)}
     >
      Move Action Plans, Listing Alerts, News Letter and Market Updates from
      selected {capitalizeFirstLetter(type)}s to main{' '}
      {capitalizeFirstLetter(type)}.
     </Checkbox>

     <div className="d-flex mt-3">
      <div style={{ minWidth: '16px' }}>
       <SVGWarningCircle style={{ marginTop: '3px' }} />
      </div>
      <p style={{ paddingLeft: '6px', textAlign: 'justify' }}>
       When {type}s are merged into a single main {type}, the merging {type}s
       will be removed from all leads, and the main {type} will be assigned to
       all leads belonging to the merging {type}s if it is not already assigned.
      </p>
     </div>
     {checked && (
      <div className="d-flex ">
       <div style={{ minWidth: '16px' }}>
        <SVGWarningCircle style={{ marginTop: '3px' }} />
       </div>
       <p style={{ paddingLeft: '6px', textAlign: 'justify' }}>
        Action plans, listing alerts, newsletters, and market updates from the
        selected {type}s will be assigned to leads under the main {type} if they
        are not already assigned.
       </p>
      </div>
     )}
    </div>
   </div>
  </Modal>
 )
}
export default MergeTSSModal
