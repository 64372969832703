import { Col, Form, Input, Modal, Row, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { SMSInputArea } from 'components/shared/Common/SMSInputArea'
import React, { FC, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router'
import { InfinityNewType, TMode } from 'types'
import //  TGetSMSFolders,
// TGetSMSItemsList
'features/templates/types'
import useFetchPage2 from 'hooks/useFetchPage2'
import UpdateTemplateFolder from 'features/templates/components/UpdateTemplateFolder'
import { queryClient } from 'lib/react-query'
import { InfiniteData } from 'react-query'
import TrimFirstSpaceInput from 'components/elements/Form/TrimInput'
import { TPutTextTemplate } from '../types'
import {
 //  refetchTextTemplateList,
 useAddTextTemplateMutation,
 useEditTextTemplateMutation,
} from '../api'
import { TemplateFolderInput } from './EmailTemplateForm'

const FormINit: TPutTextTemplate = {
 name: '',
 body: '',
 id: '',
}
const TextTemplateForm: FC<{
 mode?: TMode
 editData?: TPutTextTemplate
 onSuccess?: (v?: any) => void
 //  folderData?: TGetSMSFolders
 folderId?: number
 showFolderList?: boolean
 folderName?: string
 onCancel?: () => void
}> = ({
 mode = 'ADD',
 editData,
 onSuccess,
 folderId,
 //  showFolderList,
 folderName,
 onCancel,
}) => {
 const [addTextTemplate, { isLoading: addLoading }] =
  useAddTextTemplateMutation()

 const [saveAsTextTemplate, { isLoading: saLoading }] =
  useAddTextTemplateMutation()

 const [editTextTemplate, { isLoading: editLoading }] =
  useEditTextTemplateMutation()
 const [showAddFolder, setShowAddFolder] = useState(false)

 const [form] = Form.useForm<TPutTextTemplate>()

 const [showSaveAsModal, setShowAsModal] = useState(false)

 const HandleSubmit = async (): Promise<void> => {
  const values = await form.validateFields()

  values.folder_id = `${(values?.folder_id as any)?.[0]?.id}`

  if (mode === 'EDIT' && editData) {
   await editTextTemplate({ ...values, id: editData.id })
    .unwrap()
    .then((res) => {
     form.resetFields()
     if (res) {
      onSuccess?.(res)
     } else {
      onSuccess?.()
     }
    })
  } else {
   await addTextTemplate({ ...values })
    .unwrap()
    .then((res) => {
     form.resetFields()
     if (res) {
      onSuccess?.(res)
     } else {
      onSuccess?.()
     }
    })
  }
 }
 const handleSubmitSaveAs = async (title: string): Promise<void> => {
  const values = await form.validateFields()
  await saveAsTextTemplate({
   ...values,
   folder_id: (values?.folder_id as any)?.[0]?.id,
   name: title,
  })
   .unwrap()
   .then((res) => {
    if (res) {
     onSuccess?.(res)
    } else {
     onSuccess?.()
    }
    setShowAsModal(false)
   })
  setShowAsModal(false)
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    name: editData.name,
    body: editData.body,
    ...(editData?.folder_id && editData?.folder_name
     ? {
        folder_id: [
         { id: editData?.folder_id, title: editData?.folder_name },
        ] as any,
       }
     : {}),
   })
  }
  return () => {
   form.resetFields()
  }
 }, [mode, editData])

 const [foldersSearchKey, setFolderSearchKey] = useState('')

 const { isLoading, hasMore, fetchMore, flatData } = useFetchPage2<{
  id: number
  name: string
 }>('folder-search', {
  method: 'post',
  // body: { search: foldersSearchKey },
  params: `type=sms&search=${foldersSearchKey}`,
 })

 useEffect(() => {
  if (folderId && folderName) {
   form.setFieldValue('folder_id', [{ id: folderId, title: folderName }])
  }
 }, [folderId, folderName])

 return (
  <>
   <Form layout="vertical" initialValues={FormINit} form={form}>
    <Row gutter={15}>
     <Col span={24}>
      <Form.Item
       label="Template Name"
       name="name"
       rules={[
        { required: true, message: 'Template Name is required' },
        { min: 3, message: 'Minimum 3 Characters required' },
       ]}
      >
       {/* <Input placeholder="Template Name" /> */}
       <TrimFirstSpaceInput placeholder="Template Name" />
      </Form.Item>
     </Col>
     {/* {showFolderList && ( */}
     <Col span={24}>
      <Row>
       <Col span={23}>
        <Form.Item
         name="folder_id"
         label="Folder Name"
         rules={[{ required: true, message: 'Please Choose a Folder' }]}
        >
         <TemplateFolderInput
          fetchMore={fetchMore}
          hasMore={hasMore}
          isLoading={isLoading}
          flatData={flatData}
          setSearchKey={setFolderSearchKey}
         />
        </Form.Item>
       </Col>
       <Col span={1} className="d-flex align-items-center">
        <BaseButton
         tooltipText="Add New Folder"
         onClick={() => setShowAddFolder(true)}
         buttonType="gamma"
         icon={<i className="far fa-plus" />}
         customClass="ms-2"
         style={{ marginTop: '5px' }}
        />
       </Col>
      </Row>
     </Col>
     {/* )} */}
     <Col span={24}>
      <Form.Item
       label="Content"
       name="body"
       rules={[{ required: true, message: 'Content is required' }]}
      >
       <SMSInputArea />
      </Form.Item>
     </Col>
     <Col span={24}>
      <Space className="w-100 d-flex justify-content-end">
       <BaseButton
        buttonStyle="outline"
        buttonType="danger"
        onClick={() => onCancel?.()}
       >
        Cancel
       </BaseButton>
       <BaseButton
        buttonType="primary"
        onClick={HandleSubmit}
        loading={mode === 'EDIT' ? editLoading : addLoading}
       >
        {mode === 'EDIT' ? 'Update Text Template' : 'Add Text Template'}
       </BaseButton>
       {mode === 'EDIT' && (
        <BaseButton onClick={() => setShowAsModal(true)} buttonType="info">
         Save As
        </BaseButton>
       )}
      </Space>
     </Col>
    </Row>
   </Form>
   {showSaveAsModal && (
    <SaveAsTemplateModal
     Loading={saLoading}
     handleSubmitSaveAs={handleSubmitSaveAs}
     visible={showSaveAsModal}
     onCancel={() => setShowAsModal(false)}
    />
   )}
   {showAddFolder && (
    <UpdateTemplateFolder
     from="SMS"
     mode="ADD"
     onCancel={() => setShowAddFolder(false)}
     onSuccess={(newData) => {
      const cache = queryClient.getQueryData<
       InfiniteData<
        InfinityNewType<{
         id: number
         name: string
        }>
       >
      >(['folder-search', '', `type=sms&search=${foldersSearchKey}`])

      const updatedData: any = {
       ...cache,
       pages:
        cache?.pages.map((page, index) =>
         index === 0
          ? {
             ...page,
             data: [newData ?? [], ...(page?.data ?? [])],
            }
          : page,
        ) ?? [],
      }
      if (updatedData && updatedData?.pages) {
       queryClient.setQueryData<
        InfiniteData<
         InfinityNewType<{
          id: number
          name: string
         }>
        >
       >(
        ['folder-search', '', `type=sms&search=${foldersSearchKey}`],
        (data) => updatedData ?? {},
       )
      }
      form.setFieldValue('folder_id', [
       { id: newData?.id, title: newData?.name },
      ])
     }}
    />
   )}
  </>
 )
}

export default TextTemplateForm

export const SaveAsTemplateModal: FC<{
 Loading?: boolean
 handleSubmitSaveAs?: (title: string) => Promise<void>
 visible?: boolean
 onCancel?: () => void
}> = ({ visible, onCancel, handleSubmitSaveAs, Loading }) => {
 const [form] = Form.useForm<{ name: string }>()

 //  const [input, setInput] = useState('')
 return (
  <Modal
   confirmLoading={Loading}
   //  okButtonProps={{ disabled: !input }}
   onOk={async () => {
    const values = await form.validateFields()
    await handleSubmitSaveAs?.(values?.name)
    form?.resetFields()
   }}
   okText="Save As"
   title="Save As"
   onCancel={onCancel}
   visible={visible}
  >
   <Form layout="vertical" initialValues={{ name: '' }} form={form}>
    <Form.Item
     name="name"
     label="Template Name"
     rules={[
      { required: true, message: 'Template Name is required' },
      { min: 3, message: 'Minimum 3 Characters required' },
     ]}
    >
     <TrimFirstSpaceInput placeholder="Enter A New Template Name" />
     {/* <Input
      // value={input}
      // onChange={(e) => setInput(e.target.value)}
      placeholder="Enter A New Template Name"
     /> */}
    </Form.Item>
   </Form>
  </Modal>
 )
}
