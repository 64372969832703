import { Spin, Modal } from 'antd'
import { useGetTextTemplateByIdQuery } from 'features/settings'
import TextTemplateForm from 'features/settings/components/TextTemplateForm'
import { FC, useEffect } from 'react'
import { TMode } from 'types'

const TextTemplateModal: FC<{
 editId?: any
 visible?: boolean
 onCancel?: () => void
 mode?: TMode
 onSuccess?: (v?: any) => void
 folderId?: number
 folderName?: string
}> = ({
 visible,
 onCancel,
 mode = 'ADD',
 editId,
 onSuccess,
 folderId,
 folderName,
}) => {
 const { data, isLoading, refetch } = useGetTextTemplateByIdQuery(
  { id: editId },
  { refetchOnMountOrArgChange: true, skip: mode === 'ADD' },
 )

 useEffect(() => {
  refetch?.()
 }, [])

 return (
  <Modal
   footer={null}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} Text Template`}
   width={1000}
   onCancel={onCancel}
   visible={visible}
  >
   <Spin spinning={isLoading}>
    <TextTemplateForm
     onSuccess={(newData) => {
      onCancel?.()
      onSuccess?.(newData)
     }}
     mode={mode}
     editData={data}
     //   {
     //   ...(data as any),
     //   ...(mode === 'EDIT'
     //    ? { folder_id: folderId, folder_name: folderName }
     //    : {}),
     //  }
     showFolderList
     onCancel={onCancel}
    />
   </Spin>
  </Modal>
 )
}

export default TextTemplateModal
