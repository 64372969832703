import { Input, Select } from 'antd'
import { useGlobal } from 'raducer/global'
import React, { FC, useEffect, useRef, useState } from 'react'

const EmailSubjectInput: FC<{
 readOnly?: boolean
 type?: 'page' | 'inner'
 onChange?: (val?: string) => void
 value?: string
 mergeField?: boolean
}> = ({ onChange, value, readOnly, mergeField = true }) => {
 const inputRef = useRef<any>(null)
 const [uValue, setValue] = useState(value ?? '')
 const { global } = useGlobal()

 useEffect(() => {
  if (value) {
   setValue(value)
  }
 }, [value])
 return (
  <div className="sw-subject-input">
   <Input
    readOnly={readOnly}
    ref={inputRef}
    onChange={(e) => {
     //  if (e?.target?.value.startsWith(' ')) return
     setValue(
      e?.target?.value?.replace(/ /g, '').length > 0 ? e?.target?.value : '',
     )
     onChange?.(
      e?.target?.value?.replace(/ /g, '').length > 0 ? e?.target?.value : '',
     )
    }}
    placeholder="Subject"
    value={uValue}
    addonAfter={
     mergeField && (
      <Select
       className=""
       value="Merge Field"
       onChange={(val) => {
        const ref = inputRef?.current
        ref?.focus()
        const start = uValue.substring(0, ref?.input.selectionStart || 0)
        const end = uValue.substring(ref?.input.selectionStart || 0)
        const text = start + val + end
        setValue(text)
        onChange?.(text)
       }}
       options={
        global.subject_mergefield &&
        global?.subject_mergefield.map((item) => ({
         value: item.id,
         label: item.name,
        }))
       }
       placeholder="Merge Field"
       style={{ width: 140 }}
      />
     )
    }
   />
  </div>
 )
}

export default EmailSubjectInput
