/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import { Col, Modal, Row } from 'antd'
import { BtnAnch, globalConfirm } from 'components/elements/global'
import {
 Dispatch,
 FC,
 SetStateAction,
 useEffect,
 useMemo,
 useState,
} from 'react'
import useAppControl from 'hooks/useAppControl'
import { TProLeadsSelectAll } from 'components/shared/SendWidget/EmailSendWidget'
import { BaseSelectorOption } from '../BaseSelector'
import LeadSelector from '../LeadSelector/LeadSelector'

const SWToBar: FC<{
 showAdd?: boolean
 type: 'phoneNumber' | 'email'
 onChange?: (v: BaseSelectorOption[]) => void
 value?: BaseSelectorOption[]
 readonly?: boolean
 setProLeadsSelectAll?: Dispatch<SetStateAction<TProLeadsSelectAll | undefined>>
 proLeadsSelectAll?: TProLeadsSelectAll
 ccMailValue?: string[]
 onConfirmShowModal?: () => void
}> = ({
 onChange,
 value,
 type,
 showAdd = true,
 readonly = false,
 setProLeadsSelectAll,
 proLeadsSelectAll,
 ccMailValue,
 onConfirmShowModal,
}) => {
 const [show, setShow] = useState(false)
 const [selected, setSelected] = useState<BaseSelectorOption[]>(value || [])
 const { sendWidget, updateSendSMSWidget } = useAppControl()

 const {
  selectAll,
  unSelected,
  selectedToCount,
  showMoreLabel,
  customtSelectAll,
 } = sendWidget || {}

 const isSelectAll = useMemo(() => {
  if (customtSelectAll !== undefined) {
   return customtSelectAll !== null
  }
  return (selectAll || proLeadsSelectAll?.selectAll) ?? false
 }, [customtSelectAll, selectAll, proLeadsSelectAll?.selectAll])

 useEffect(() => {
  if (value) {
   if (typeof value === 'string') {
    setSelected([value])
   } else {
    setSelected(value)
   }
  }
 }, [value])

 const showModal = (): void => {
  if (type === 'email' && ccMailValue?.length) {
   globalConfirm({
    content:
     'If you want to add more than 1 recipient, it will clear your CC mail values.',
    onOk: () => {
     setShow(true)

     if (
      (Array.isArray(selected) && selected?.length > 0) ||
      !Array.isArray(selected)
     ) {
      onConfirmShowModal?.()
     }
    },
   })
  } else {
   setShow(true)
  }
 }

 return (
  <>
   <div
    className="sw-to-bar "
    onClick={() => {
     if (!isSelectAll && !readonly) {
      showModal()
     }

     //  !isSelectAll ? (readonly ? undefined : showModal()) : undefined
    }}
    style={{ cursor: readonly || isSelectAll ? 'auto' : 'pointer' }}
   >
    <div className="input-to-area">
     <div className="sw-bar-container">
      {(
       proLeadsSelectAll?.show
        ? proLeadsSelectAll?.showMoreLabel
        : showMoreLabel
      ) ? (
       <span>
        {(value?.length ?? 0) > 0 ? (value as any)?.[0] : ''}
        {(value?.length ?? 0) > 1
         ? ` and ${
            (proLeadsSelectAll?.show
             ? proLeadsSelectAll?.selectedCount ?? 1
             : selectedToCount ?? 1) - 1
           } more...`
         : ''}
       </span>
      ) : selected ? (
       <Row gutter={[6, 6]} className="m-0">
        {Array.isArray(selected) && selected?.length > 0 ? (
         selected?.map?.((v, i) => (
          <Col
           key={i}
           onClick={(e) => {
            e.stopPropagation()
            setShow(false)
           }}
           style={{ height: '22px' }}
          >
           <div className="sw-to-bar-tag">
            <span>{v as any}</span>
            {showAdd && !readonly && (
             <BtnAnch
              onClick={() => {
               //  if (type === 'email' && selected?.length === 1) {
               //   onConfirmShowModal?.()
               //  }

               setSelected((prv) => {
                let newData = prv
                newData = prv.filter((m) => m !== v)
                onChange?.(newData)
                return [...newData]
               })
              }}
             >
              <i className="fal fa-times" />
             </BtnAnch>
            )}
           </div>
          </Col>
         ))
        ) : !Array.isArray(selected) ? (
         <Col
          key={Math.random()}
          onClick={(e) => {
           e.stopPropagation()
           setShow(false)
          }}
         >
          <div className={`sw-to-bar-tag ${readonly ? 'read-only' : ''}`}>
           <span>{selected as any}</span>
           {showAdd && !readonly && (
            <BtnAnch
             onClick={() => {
              // if (type === 'email') {
              //  onConfirmShowModal?.()
              // }

              setSelected((prv) => {
               let newData = prv
               newData = prv.filter((m) => m !== selected)
               onChange?.(newData)
               return [...newData]
              })
             }}
            >
             <i className="fal fa-times" />
            </BtnAnch>
           )}
          </div>
         </Col>
        ) : (
         <BtnAnch
          onClick={(e) => {
           e?.stopPropagation()
           if (!readonly) {
            showModal()
           }
          }}
          className="sw-bar-placeholder"
         >
          Select Lead
         </BtnAnch>
        )}
       </Row>
      ) : (
       <BtnAnch
        onClick={(e) => {
         e?.stopPropagation()
         if (!readonly) {
          showModal()
         }
        }}
        className="sw-bar-placeholder"
       >
        Select Lead
       </BtnAnch>
      )}
     </div>
    </div>
    {showAdd && !readonly && !showMoreLabel && (
     <BtnAnch
      onClick={(e) => {
       e?.stopPropagation()
       if (!readonly) {
        showModal()
       }
      }}
      className="sw-to-bar-action"
      icon={<i className="far fa-plus" />}
     />
    )}
   </div>

   {show && (
    <SWToBarModal
     type={type}
     selectedValue={selected}
     handleOkay={(v, proSA) => {
      setSelected(v?.filter((i: any) => i !== ''))
      onChange?.(v?.filter((i: any) => i !== ''))
      setProLeadsSelectAll?.(proSA)
     }}
     visible={show}
     onCancel={() => setShow(false)}
     proLeadsSelectAll={proLeadsSelectAll}
     setProLeadsSelectAll={setProLeadsSelectAll}
    />
   )}
  </>
 )
}

export default SWToBar

const SWToBarModal: FC<{
 type: 'phoneNumber' | 'email'
 selectedValue?: BaseSelectorOption[]
 visible?: boolean
 onCancel?: () => void
 handleOkay?: (v: BaseSelectorOption[], proSA?: TProLeadsSelectAll) => void
 setProLeadsSelectAll?: Dispatch<SetStateAction<TProLeadsSelectAll | undefined>>
 proLeadsSelectAll?: TProLeadsSelectAll
}> = ({
 visible,
 onCancel,
 handleOkay,
 selectedValue,
 type,
 proLeadsSelectAll,
 //  setProLeadsSelectAll,
}) => {
 const [selected, setSelected] = useState<BaseSelectorOption[]>(
  selectedValue ?? [],
 )

 const [selectAll, setSelectAll] = useState<TProLeadsSelectAll | undefined>(
  proLeadsSelectAll,
 )

 useEffect(() => {
  setSelectAll(proLeadsSelectAll)
 }, [proLeadsSelectAll])

 useEffect(() => {
  if (selectedValue) {
   setSelected?.(selectedValue)
  }
 }, [selectedValue])

 return (
  <Modal
   {...{ onCancel }}
   visible={visible}
   title="Select Lead"
   bodyStyle={{ padding: 0 }}
   onOk={() => {
    handleOkay?.(selected, selectAll)
    onCancel?.()
   }}
  >
   <LeadSelector
    type={type}
    value={selected}
    onChange={(v) => setSelected(v ?? [])}
    proLeadsSelectAll={selectAll}
    setProLeadsSelectAll={setSelectAll}
   />
  </Modal>
 )
}
