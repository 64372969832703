import { Form, Row, Col, Input, Timeline, Avatar, Card, Checkbox } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalToaster } from 'components/elements/global'
import { ADMIN_PREFIX_PATH } from 'config'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import {
 arrayMove,
 SortableContainer,
 SortableElement,
 SortableHandle,
} from 'react-sortable-hoc'
import { TMode } from 'types'
import {
 useAddActionPlanMutation,
 useLazyGetActionPlanByIdQuery,
 useLazyViewActionPlanQuery,
 useUpdateActionPlanMutation,
} from '../../api'
import { useActionForm } from '../../providers/ActionFormProvider'
import { TActionPlanForm } from '../../types'
import ActionPlanFormSteps from './ActionPlanFormSteps'

const DragHandle = SortableHandle(() => (
 <i style={{ cursor: 'row-resize' }} className="far fa-bars " />
))

const SortableItem = SortableElement(({ value, onRemove, name }: any) => (
 <Timeline.Item
  dot={
   <Avatar className="bg-primary" size="small">
    {value + 1}
   </Avatar>
  }
 >
  <ActionPlanFormSteps
   name={name}
   title={<DragHandle key={1} />}
   onRemove={onRemove}
  />
 </Timeline.Item>
)) as any

const SortableContainerFun = SortableContainer(({ children }: any) => (
 <div>{children}</div>
)) as any

const ActionPlanForm: FC<{
 onCancel: () => void
 refetchList: () => void
}> = ({ onCancel, refetchList }) => {
 const [addActionPlan, { isLoading: addLoading }] = useAddActionPlanMutation()
 const [updateActionPlan, { isLoading: upLoading }] =
  useUpdateActionPlanMutation()
 const { form, mode, editId } = useActionForm()
 const formInitValues: TActionPlanForm = {
  name: '',
 }

 const [getActionPlanById] = useLazyGetActionPlanByIdQuery()
 const [getViewActionPlanById] = useLazyViewActionPlanQuery()
 const handleSubmit = async () => {
  const values = await form?.validateFields()

  if (values && values.steps && values.steps.length > 0) {
   const formData = new FormData()
   formData.append('name', `${values.name}`)
   formData.append('reply_flag', `${values?.reply_flag ? 'Yes' : null}`)
   if (values.steps && values.steps.length > 0) {
    values.steps.forEach((v, key) => {
     formData.append(`steps[${key}][action]`, `${v.action}`)
     formData.append(`steps[${key}][run_on]`, `${v.run_on}`)
     v.param1 && formData.append(`steps[${key}][param1]`, `${v.param1}`)
     v.param2 && formData.append(`steps[${key}][param2]`, `${v.param2}`)
     v.id && formData.append(`steps[${key}][id]`, `${v.id}`)
    })
   }
   if (mode === 'EDIT' && editId) {
    await updateActionPlan({ body: formData, id: editId }).unwrap()
    getActionPlanById?.({ id: editId })
    getViewActionPlanById?.({ id: editId })
    onCancel()
    refetchList?.()
   } else {
    await addActionPlan(formData).unwrap()
    onCancel()
    refetchList?.()
   }
  } else {
   globalToaster({
    content: 'Please select at least one step to continue',
    type: 'error',
   })
  }
 }

 const handleChangeArrayindex = ({
  oldIndex,
  newIndex,
 }: {
  oldIndex: number
  newIndex: number
 }) => {
  const oldFormData: TActionPlanForm = form?.getFieldsValue(true)
  const oldStep = oldFormData.steps
  if (oldStep) {
   form?.setFieldsValue({
    ...oldFormData,
    steps: arrayMove(oldStep, oldIndex, newIndex),
   })
  }
 }

 if (!form) {
  return null
 }

 return (
  <Form initialValues={formInitValues} form={form} layout="vertical">
   <Row gutter={[15, 15]}>
    <Col span={24}>
     <Form.Item
      rules={[{ required: true, message: 'Please select an Action' }]}
      name="name"
      label="Action plan name"
     >
      <Input placeholder="Enter the name of the Action Plan" />
     </Form.Item>
    </Col>
    <Col span={24}>
     <Form.Item name="reply_flag" valuePropName="checked">
      <Checkbox>
       Pause the action plan when the lead replies to the mail
      </Checkbox>
     </Form.Item>
    </Col>
    <Col span={24}>
     <Form.List name="steps">
      {(fields, { add, remove }) => (
       <>
        <SortableContainerFun
         onSortEnd={handleChangeArrayindex}
         lockAxis="y"
         useDragHandle
        >
         {fields.map((field) => (
          <SortableItem
           form={form}
           name={field.name}
           onRemove={() => remove(field.name)}
           key={`item-${field.name}`}
           index={field.name}
           value={field.name}
          />
         ))}
        </SortableContainerFun>
        <Timeline.Item
         dot={
          <Avatar className="bg-primary" size="small">
           {fields && fields.length + 1}
          </Avatar>
         }
        >
         <Card
          onClick={async () => {
           await form.validateFields(['name'])
           add()
          }}
          className="action-card action-add"
         >
          <i className="far fa-plus" /> Add Action
         </Card>
        </Timeline.Item>
       </>
      )}
     </Form.List>
    </Col>
   </Row>
   <div className="d-flex justify-content-end">
    <BaseButton buttonStyle="outline" buttonType="danger" onClick={onCancel}>
     Cancel
    </BaseButton>
    <BaseButton
     loading={addLoading || upLoading}
     onClick={handleSubmit}
     customClass="mx-2"
     buttonType="success"
    >
     {mode === 'EDIT' ? 'Update' : 'Submit'}
    </BaseButton>
   </div>
  </Form>
 )
}

export default ActionPlanForm
