import { Tooltip } from 'antd'
import React, { CSSProperties, FC } from 'react'
import { utils } from 'utils'

const ViewAttachments: FC<{
 attachments?: { path: string; size: number; file_name?: string }[]
 mode?: 'row' | 'column'
 classNames?: { wrapper?: string; item?: string }
 styles?: { item?: CSSProperties; wrapper?: CSSProperties }
}> = ({ attachments, mode = 'column', classNames, styles }) => {
 if ((attachments?.length ?? 0) < 1) {
  return null
 }
 return (
  <div
   style={styles?.wrapper ?? {}}
   className={`attachments-wrapper ${mode === 'row' ? 'row-wrapper' : ''}`}
  >
   {/* {attachments?.map((i, n) => {
    return (
     // <a
     //  target={
     //   ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
     //    `${i?.path?.split('.').at(-1)}`,
     //   )
     //    ? '_blank'
     //    : '_self'
     //  }
     //  href={i?.path}
     //  key={n}
     //  rel="noreferrer"
     // >
     //  <div className="attachment">
     //   <i className="fas fa-file" />
     //  </div>
     // </a>
     <div key={n} className="att-item">
      <span className="line-clamp-1">
       <a
        target={
         ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
          `${i?.path?.split('.').at(-1)}`,
         )
          ? '_blank'
          : '_self'
        }
        href={i?.path}
        rel="noreferrer"
       >
        {' '}
        {i?.path}
       </a>
      </span>
      <span>{`(${utils.convertToMb(i?.size)} MB)`}</span>
     </div>
    )
   })} */}

   {attachments?.map((i, n) => {
    return (
     <Tooltip key={n} overlay={i?.file_name ?? null}>
      <div className="att-item" style={styles?.item ?? {}}>
       <span
        className="line-clamp-1"
        style={
         mode === 'row'
          ? {
             width: '200px',
            }
          : {}
        }
       >
        <a
         target={
          ['jpg', 'jpeg', 'png', 'svg', 'txt', 'pdf']?.includes(
           `${i?.path?.split('.').at(-1)}`,
          )
           ? '_blank'
           : '_self'
         }
         href={i?.path}
         rel="noreferrer"
         style={
          mode === 'row'
           ? {
              wordWrap: 'break-word',
              wordBreak: 'break-all',
             }
           : {}
         }
        >
         {' '}
         {i?.file_name}
        </a>
       </span>
       <span
        style={{
         ...(mode === 'row'
          ? {
             width: 'max-content',
             paddingLeft: '10px',
            }
          : {}),
        }}
       >{`(${utils.convertToMb(i?.size)} MB)`}</span>
      </div>
     </Tooltip>
    )
   })}
  </div>
 )
}

export default ViewAttachments
