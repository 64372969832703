import { Card, Spin, Timeline } from 'antd'
import BaseTag from 'components/elements/BaseTag'
import {
 SvgActionPlanIcon,
 SvgEmailIcon,
 SvgNoteIcon,
 SvgStageIcon,
 SvgTagsIcon,
 SvgTaskIcon,
 SvgTextIcon,
} from 'components/icons'
import React, { FC } from 'react'
import ViewAttachments from 'components/elements/ViewAttachments'
import { useViewActionPlanQuery } from '../../api'
import { TActionPlanType, TActionPlanViewStep } from '../../types'

const View: FC<{ id: string }> = ({ id }) => {
 const { data, isLoading } = useViewActionPlanQuery({
  id,
 })

 const buildContent = (item: TActionPlanViewStep) => {
  switch (item.type) {
   case 'Create Task':
    return (
     <Card type="inner" className="action-card" title={item.data.task_name}>
      <div>
       <p>{item.data.task_name}</p>
       <p>{item.data.task_type}</p>
      </div>
     </Card>
    )

   case 'Send Email':
    return (
     <Card type="inner" className="action-card" title={item.data.name}>
      <div>
       <p> Name : {item.data.name}</p>
       <p> Subject : {item.data.subject}</p>
       {item.data.body && (
        <p
         className="ap-steps-email-body"
         dangerouslySetInnerHTML={{ __html: `${item.data.body}` }}
        />
       )}
       <ViewAttachments
        mode="row"
        attachments={item?.data?.attachment ?? []}
        styles={{
         item: { width: '300px' },
         wrapper: { wordBreak: 'normal' },
        }}
       />
      </div>
     </Card>
    )
   case 'Send Text':
    return (
     <Card type="inner" className="action-card" title={item.data.name}>
      <div>
       <p> Name : {item.data.name}</p>
       <p>{item.data.body}</p>
      </div>
     </Card>
    )
   case 'Change Stage':
    return (
     <Card type="inner" className="action-card" title={item.type}>
      <div>
       <p>{item.data.stage_name}</p>
      </div>
     </Card>
    )
   case 'Add Note':
    return (
     <Card type="inner" className="action-card" title="Note">
      <div>
       <p>{item.data.content}</p>
      </div>
     </Card>
    )
   case 'Add Tag(s)':
   case 'Remove Tag(s)':
   case 'Remove all Tags':
    return (
     <Card type="inner" className="action-card" title={item.type}>
      <div>
       {item?.data?.tags?.map?.((i, index) => (
        <BaseTag label={i} key={index} />
       ))}
      </div>
     </Card>
    )
   case 'Assign Action Plan':
   case 'Pause Specific Action plan':
    return (
     <Card type="inner" className="action-card" title={item.type}>
      {item?.data?.action_plan?.map?.((i, index) => (
       <div key={index}>
        <p>{i}</p>
       </div>
      ))}
     </Card>
    )
   case 'Repeat Action Plan':
    return (
     <Card type="inner" className="action-card" title={item.type}>
      <div>
       <p>{data?.name}</p>
      </div>
     </Card>
    )

   default:
    return (
     <Card type="inner" className="action-card" title={item.type}>
      <span>{item.data.action_plan.join(' , ')}</span>
     </Card>
    )
  }
 }

 const buildActionIcons = (key: TActionPlanType) => {
  switch (key) {
   case 'Create Task':
    return <SvgTaskIcon />
   case 'Send Email':
    return <SvgEmailIcon />
   case 'Send Text':
    return <SvgTextIcon />
   case 'Change Stage':
    return <i className="far fa-th" style={{ fontSize: '30px' }} />
   case 'Add Note':
    return <SvgNoteIcon />
   case 'Add Tag(s)':
   case 'Remove Tag(s)':
   case 'Remove all Tags':
    return <i className="far fa-tags" style={{ fontSize: '30px' }} />
   case 'Pause all other Action plans':
   case 'Pause Specific Action plan':
   case 'Assign Action Plan':
   case 'Repeat Action Plan':
    return <SvgActionPlanIcon />

   default:
    return <SvgEmailIcon />
  }
 }
 return (
  <Spin spinning={isLoading}>
   <Timeline className="action-timeline-view">
    {data?.steps?.map?.((item, index) => (
     <Timeline.Item
      key={index}
      color="primary"
      dot={
       <div className="tim-dot">
        {buildActionIcons(item.type)}
        <h5>{item.run_on}</h5>
       </div>
      }
     >
      {buildContent(item)}
     </Timeline.Item>
    ))}
   </Timeline>
  </Spin>
 )
}

export default View
