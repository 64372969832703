import { TFileExt } from 'types'

export default class Utils {
 static shortName = (name: string): string => {
  const splitName = name.split(' ')
  const shortName = splitName.map((e) => e.charAt(0))
  return shortName
   .join('')
   .toUpperCase()
   .slice(0, 2)
   .replace(/[^a-zA-Z0-9]/g, '')
 }

 static getExtension = (fileName: string) => {
  const extension = fileName.split('.').pop()
  return extension
 }

 static getExtNameFromUrl = (url = ''): TFileExt => {
  const temp = url.split('.')
  const filename = temp[temp.length - 1]
  const ext = filename.split(/#|\?/)[0] as TFileExt
  return ext
 }
}
