import { MDataTable } from 'components/shared/MDataTable'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { TListTagStageSource } from 'features/settings/types'
import useFetchPage2 from 'hooks/useFetchPage2'
import useSelectAll from 'hooks/useSelectAll'
import ListContentLayout from 'layout/ListContentLayout'
import { baseTime } from 'lib'
import { FC, useState, useEffect } from 'react'
import { capitalizeFirstLetter } from 'utils/functions'
import { BaseButton } from 'components/elements/BaseButton'
import TSSFormModal from 'features/settings/components/TSSFormModal'
import {
 BtnAnch,
 globalConfirm,
 globalToaster,
} from 'components/elements/global'
import { SvgDeleteIcon, SVGWarningCircle } from 'components/icons'
import { useDeleteGroupMutation } from 'features/settings/api'
import { Input, Space, Tag } from 'antd'
import TSSActionBlock from './TSSActionBlock'
import TSSLeadsModal from './TSSLeadsModal'

type TTableColumnsProps = {
 type: 'tag' | 'stage' | 'source'
 handleClickEdit: (v: TListTagStageSource) => void
 handleClickDelete: (id: number, name?: string) => void
 onClickLeads?: (v: {
  leads: { name?: string; id: number }[]
  tag: { id: number; name: string; is_mergable?: boolean }
 }) => void
}

const TableColumns = ({
 type,
 handleClickEdit,
 handleClickDelete,
 onClickLeads,
}: TTableColumnsProps): MDataTableColumns<TListTagStageSource>[] => [
 {
  title: `${capitalizeFirstLetter(type)} Name`,
  dataIndex: 'name',
  size: 250,
 },
 {
  title: 'Number of Leads ',
  dataIndex: 'count',
  size: 130,
  render: (_e, r) => {
   if ((r?.count ?? 0) > 0) {
    return (
     <BtnAnch
      className="colored-link"
      onClick={() => {
       onClickLeads?.({
        leads: r?.leads ?? [],
        tag: { id: r?.id, name: r?.name ?? '', is_mergable: r?.is_mergable },
       })
      }}
     >
      {r?.count}
     </BtnAnch>
    )
   }
   return <a className="text-dark">{r?.count}</a>
  },
 },
 {
  title: 'Created By',
  dataIndex: 'owner',
  size: 250,
 },
 {
  title: 'Created On',
  dataIndex: 'created_at',
  render: (val) => baseTime(val).format('DD MMM YYYY'),
  size: 120,
 },
 {
  title: 'Action',
  size: 70,
  fixed: 'right',
  render: (_, record) =>
   record?.is_admin === 1 ? (
    <Space>
     <BaseButton
      onClick={() => handleClickEdit?.(record)}
      tooltipText="Edit"
      shape="circle"
      size="small"
      buttonType="info"
      icon={<i className="fas fa-edit" />}
     />
     {type === 'tag' && (
      <BaseButton
       onClick={() => handleClickDelete?.(record?.id, record?.name)}
       tooltipText="Delete"
       shape="circle"
       size="small"
       buttonType="danger"
       icon={<SvgDeleteIcon />}
      />
     )}
    </Space>
   ) : (
    ''
   ),
 },
]

const TagStageSourceList: FC<{ type: 'tag' | 'stage' | 'source' }> = ({
 type,
}) => {
 const [editData, setEditData] = useState<TListTagStageSource | null>(null)
 const [leadsData, setLeadsData] = useState<{
  leads: { name?: string; id: number }[]
  tag: { id: number; name: string; is_mergable?: boolean }
 } | null>(null)
 const [searchKey, setSearchKey] = useState('')
 const [deleteGroup] = useDeleteGroupMutation()
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  isRefetching,
  meta,
 } = useFetchPage2<TListTagStageSource>('get-groups', {
  method: 'get',
  params: `type=${type}&search=${encodeURIComponent(searchKey)}`,
 })

 const {
  selectedKeys,
  onSelectRow,
  rowSelectionState,
  selectedRows,
  setSelectedRow,
 } = useSelectAll('')

 const onClickDlt = (id: number, tagName?: string): void => {
  globalConfirm({
   //    title:
   //     (selectedKeys?.length ?? 0) > 1
   //      ? `Are you sure you want to delete the following ${type}s?`
   //      : `Are you sure you want to delete this ${type}?`,
   //    title: `Are you sure you want to delete this ${type}?`,
   title: (
    <h5>{`Are you sure you want to delete this ${capitalizeFirstLetter(
     type,
    )}?`}</h5>
   ),
   content: (
    <>
     <div style={{ paddingBottom: '12px' }}>
      <Tag
       style={{
        padding: '3px 8px',
        fontSize: '15px',
        borderRadius: '6px',
       }}
       className=""
       // style={buidStyle(size ?? 'md')}
      >
       {tagName}
      </Tag>
     </div>
     <div className="" style={{ textAlign: 'left' }}>
      <div className="d-flex">
       <div style={{ minWidth: '16px' }}>
        <SVGWarningCircle style={{ marginTop: '3px' }} />
       </div>
       <p style={{ paddingLeft: '6px', textAlign: 'justify' }}>
        Tags Created by AgentRoof Cannot be deleted
       </p>
      </div>
      <div className="d-flex ">
       <div style={{ minWidth: '16px' }}>
        <SVGWarningCircle style={{ marginTop: '3px' }} />
       </div>
       <p style={{ paddingLeft: '6px', textAlign: 'justify' }}>
        When a Tag is deleted, it will be removed from all Assigned Leads,
        Action Plans, and Alerts. The Action Plans assigned to Leads using the
        Tag will be paused, while Alerts, Market Updates, and Newsletters
        assigned through the Tag will be removed.
       </p>
      </div>
     </div>
    </>
   ),
   onOk: async () => {
    await deleteGroup({
     type,
     id: [id],
    }).unwrap()
    refetch?.()
   },
  })
 }

 useEffect(() => {
  setSelectedRow([])
  setSearchKey('')
 }, [type])

 return (
  <>
   <ListContentLayout
    title={capitalizeFirstLetter(`${type}s`)}
    actions={
     <TSSActionBlock
      type={type}
      selectedRows={selectedRows}
      selectedKeys={selectedKeys}
      refetch={refetch}
     />
    }
   >
    <MDataTable
     totalData={meta?.total || 1000}
     getSelectionTooltip={(e) =>
      !e?.is_mergable ? 'This Tag cannot be merged with other Tags' : null
     }
     setIsDisabled={(e) => !e?.is_mergable}
     //  handleCellSelection={(e) => {
     //   if (!e?.is_mergable) {
     //    globalToaster({
     //     type: 'error',
     //     // content: `${capitalizeFirstLetter(
     //     //  type,
     //     // )}s from the super-admin cannot be merged.`,
     //     content:
     //      'The tag,stage,sources created from super admin can be merged except the tags ("Unsubscribed","Spam Reported","Bounced","Do Not Disturb")',
     //    })
     //    return false
     //   }
     //   return true
     //  }}
     onSelectRow={onSelectRow}
     rowSelectionState={{
      ...rowSelectionState,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={flatData}
     columns={TableColumns({
      type,
      handleClickEdit: (v) => setEditData(v),
      handleClickDelete: (id, name) => onClickDlt(id, name),
      onClickLeads: (v) => setLeadsData(v),
     })}
     selectAllCustomNode={<></>}
     //  search={{
     //   show: true,
     //   onSearch: setSearchKey,
     //   placeholder: `Search ${capitalizeFirstLetter(type)}`,
     //  }}
     customSearchNode={
      <Input
       prefix={<i style={{ color: '#777' }} className="fal fa-search me-2" />}
       allowClear
       type="search"
       value={searchKey}
       onChange={(e) =>
        e.target.value.replace(/ /g, '').length > 0
         ? setSearchKey(e.target.value)
         : setSearchKey('')
       }
       placeholder={`Search ${capitalizeFirstLetter(type)}`}
       style={{ width: '100%' }}
      />
     }
    />
   </ListContentLayout>
   {leadsData !== null && (
    <TSSLeadsModal
     onCancel={() => setLeadsData(null)}
     //  leads={leadsData?.leads}
     type={type}
     tagId={leadsData?.tag?.id}
     tagName={leadsData?.tag?.name}
     isMergable={leadsData?.tag?.is_mergable}
     refetch={refetch}
    />
   )}
   {editData !== null && (
    <TSSFormModal
     editData={{ name: editData?.name, id: editData?.id }}
     type={type}
     mode="EDIT"
     onCancel={() => setEditData(null)}
     onSuccess={() => refetch()}
     visible
    />
   )}
  </>
 )
}

export default TagStageSourceList
