/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal, Space, Checkbox, Divider, Spin } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { BaseButton } from 'components/elements/BaseButton'
import { FC, useEffect, useRef, useState } from 'react'
import { TGroupName } from 'types'
import { useTssDltLeadsMutation } from 'features/settings/api'
import { capitalizeFirstLetter } from 'utils/functions'
import useFetchPage2 from 'hooks/useFetchPage2'
import InfiniteScroll from 'react-infinite-scroller'

const TSSLeadsModal: FC<{
 onCancel?: () => void
 type?: TGroupName
 //  leads: {
 //   name?: string
 //   id: number
 //  }[]
 refetch?: () => void
 tagId?: number
 tagName?: string
 isMergable?: boolean
}> = ({
 onCancel,
 type,
 // leads,
 refetch,
 tagId,
 tagName,
 isMergable = true,
}) => {
 const [Ids, setIds] = useState<any[]>([])
 const [tssDltLeads, { isLoading: dltLeadsLoading }] = useTssDltLeadsMutation()

 const onDltLeads = async (): Promise<void> => {
  await tssDltLeads({
   tag_id: tagId,
   lead_id: Ids,
  }).unwrap()
  onCancel?.()
  refetch?.()
 }

 const {
  isLoading,
  hasMore,
  fetchMore,
  // refetch,
  flatData: leads,
  isRefetching,
  // meta,
 } = useFetchPage2<{
  name?: string
  id: number
 }>('get-group-leads', {
  method: 'get',
  params: `type=${type}&type_id=${tagId}`,
 })

 const [isOverflowBody, setIsOverflowBody] = useState(false)
 const bodyRef = useRef<HTMLDivElement>(null)

 useEffect(() => {
  if (!bodyRef?.current) return

  const checkOverflow = () => {
   const body = bodyRef.current
   if ((body?.scrollHeight ?? 0) > (body?.clientHeight ?? 0)) {
    setIsOverflowBody(true)
   } else {
    setIsOverflowBody(false)
   }
  }

  checkOverflow()
  window.addEventListener('resize', checkOverflow)
  return () => window.removeEventListener('resize', checkOverflow)
 }, [isLoading])

 return (
  <Modal
   visible
   onCancel={onCancel}
   footer={false}
   title={`Leads with ${type} '${tagName}'`}
  >
   <Spin
    className="w-100 text-center"
    // style={{ height: '200px' }}
    spinning={isLoading}
   >
    {type === 'tag' && isMergable && leads && (leads?.length ?? 0) > 0 && (
     <div
      className="d-flex justify-content-end"
      style={{
       fontWeight: '500',
       marginBottom: '8px',
       paddingRight: isOverflowBody ? '16px' : '10px',
      }}
     >
      <span
       style={{ paddingRight: '6px', cursor: 'pointer' }}
       onClick={() =>
        setIds((prv) =>
         prv?.length === leads.length ? [] : leads.map((x) => x.id),
        )
       }
      >
       Select All
      </span>
      <Checkbox
       onChange={() => {
        setIds((prv) =>
         prv?.length === leads.length ? [] : leads.map((x) => x.id),
        )
       }}
       checked={Ids?.length === leads.length}
      />
     </div>
    )}
    {leads && (leads?.length ?? 0) > 0 && (
     <div
      style={{
       maxHeight: '400px',
       padding: '10px',
       overflow: 'auto',
      }}
      ref={bodyRef}
     >
      <InfiniteScroll
       pageStart={0}
       loadMore={fetchMore}
       hasMore={hasMore}
       loader={
        <div className="text-center py-2">
         <Spin />
        </div>
       }
       useWindow={false}
      >
       {leads?.map((item, index) => (
        <AvatarStatus
         key={index}
         title={item.name}
         className="action-active-leads "
         //  onClickWrapper={() => {
         //   setIds((prv) => {
         //    const find = prv.find((m) => m === item.id)
         //    if (find) {
         //     const filter = prv.filter((x) => x !== item.id)
         //     return [...filter]
         //    }
         //    return [...prv, item.id]
         //   })
         //  }}
         {...(type === 'tag' && isMergable
          ? {
             action: <Checkbox checked={Ids.find((x) => x === item.id)} />,
             onClickWrapper: () => {
              setIds((prv) => {
               const find = prv.find((m) => m === item.id)
               if (find) {
                const filter = prv.filter((x) => x !== item.id)
                return [...filter]
               }
               return [...prv, item.id]
              })
             },
            }
          : {})}
         //  action={
         //   <Checkbox
         //    //    onChange={() => {
         //    //     setIds((prv) => {
         //    //      const find = prv.find((m) => m === item.id)
         //    //      if (find) {
         //    //       const filter = prv.filter((x) => x !== item.id)
         //    //       return [...filter]
         //    //      }
         //    //      return [...prv, item.id]
         //    //     })
         //    //    }}
         //    checked={Ids.find((x) => x === item.id)}
         //   />
         //  }
        />
       ))}
      </InfiniteScroll>
     </div>
    )}
   </Spin>
   <>
    <Divider
    //  className="my-2"
    />
    <Space>
     <BaseButton customClass="btn-secondary-custom" onClick={onCancel}>
      Cancel
     </BaseButton>
     {/* {Ids && Ids.length > 0 && type === 'tag' && ( */}
     {type === 'tag' && isMergable && (
      <BaseButton
       loading={dltLeadsLoading}
       buttonType="danger"
       onClick={onDltLeads}
       disabled={!(Ids && Ids.length > 0)}
      >
       Remove Tag ({Ids.length})
      </BaseButton>
     )}
     {/* )} */}
    </Space>
   </>
  </Modal>
 )
}

export default TSSLeadsModal
