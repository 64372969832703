import { Form, Input, Modal } from 'antd'
import {
 //  useCreateEmailFolderMutation,
 //  useSmsFoldersMutation,
 //  useUpdateEmailFolderMutation,
 useUpdateTemplateFolderMutation,
} from 'features/templates/api'
// import { TGetSMSFolders } from 'features/templates/types'
import React, { FC, useEffect } from 'react'
import { TGetTemplateFolder } from '../types'

type TUpData = Omit<TGetTemplateFolder, 'templates'>
const UpdateTemplateFolder: FC<{
 onCancel: () => void
 data?: TUpData
 mode: 'EDIT' | 'ADD'
 refetch?: () => void
 from: 'Email' | 'SMS'
 onSuccess?: (v: any) => void
}> = ({ onCancel, data, mode, refetch, from, onSuccess }) => {
 const [form] = Form.useForm<{ name: string }>()

 const [updateFolder, { isLoading: updataLoading }] =
  useUpdateTemplateFolderMutation()
 //  const [createEmailFolder, { isLoading: createEmailLoading }] =
 //   useCreateEmailFolderMutation()
 //  const [updateEmailFolder, { isLoading: updateEmailLoading }] =
 //   useUpdateEmailFolderMutation()
 const onSubmit = async (): Promise<void> => {
  const values = await form.validateFields()
  //   if (from === 'SMS') {
  await updateFolder({
   method: mode === 'ADD' ? 'post' : 'put',
   body: {
    ...values,
    type: from?.toLowerCase() as any,
    // ...(mode === 'ADD' ? { from: 'folder' } : {}),
   },
   ...(mode === 'EDIT' ? { params: `/${data?.id}` } : {}),
  })
   .unwrap()
   .then((res) => {
    onCancel?.()
    refetch?.()
    if (mode === 'ADD') {
     onSuccess?.(res)
    }
   })
  //   } else if (from === 'EMAIL') {
  //    if (mode === 'ADD') {
  //     await createEmailFolder({
  //      name: values.name,
  //     }).unwrap()
  //    } else if (mode === 'EDIT') {
  //     await updateEmailFolder({
  //      id: data?.id,
  //      name: values.name,
  //     }).unwrap()
  //    }
  //   }
  //   onCancel?.()
  //   refetch?.()
  //   if (mode === 'ADD') {
  //    onSuccess?.()
  //   }
 }

 useEffect(() => {
  if (mode === 'EDIT' && data) {
   form.setFieldsValue({ name: data?.name })
  }
 }, [data, mode])

 return (
  <Modal
   confirmLoading={updataLoading}
   onOk={onSubmit}
   okText="Save"
   cancelText="Cancel"
   title={`${mode === 'EDIT' ? 'Edit' : 'Create'} Folder`}
   onCancel={onCancel}
   visible
  >
   <Form initialValues={{ name: '' }} form={form} layout="vertical">
    <Form.Item
     rules={[
      { required: true, message: 'Folder Name is required' },
      { min: 3, message: 'Minimum 3 Characters required' },
     ]}
     name="name"
     label="Folder Name"
    >
     <Input placeholder="Folder Name" />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default UpdateTemplateFolder
