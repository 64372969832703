import { Spin, Modal } from 'antd'
import { useGetEmailTemplateByIdQuery } from 'features/settings'
import EmailTemplateForm from 'features/settings/components/EmailTemplateForm'
import { FC, useEffect } from 'react'
import { TMode } from 'types'

const EmailTemplateModal: FC<{
 editId?: any
 visible?: boolean
 onCancel?: () => void
 mode?: TMode
 onSuccess?: (v?: any) => void
 folderId?: number
 folderName?: string
}> = ({
 visible,
 onCancel,
 mode = 'ADD',
 editId,
 onSuccess,
 folderId,
 folderName,
}) => {
 const { data, isLoading, refetch } = useGetEmailTemplateByIdQuery(
  { id: editId },
  { refetchOnMountOrArgChange: true, skip: mode === 'ADD' },
 )

 useEffect(() => {
  refetch?.()
 }, [])

 return (
  <Modal
   footer={null}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} Email Template`}
   width={1000}
   onCancel={onCancel}
   visible={visible}
  >
   {/* <pre>{JSON.stringify(editId, null, 2)}</pre> */}
   <Spin spinning={isLoading}>
    <EmailTemplateForm
     onSuccess={(newData) => {
      onCancel?.()
      onSuccess?.(newData)
     }}
     mode={mode}
     editData={data}
     //  editData={{
     //   ...(data as any),
     //   ...(mode === 'EDIT'
     //    ? { folder_id: folderId, folder_name: folderName }
     //    : {}),
     //  }}
     showFolderList
     onCancel={onCancel}
     // folderId={data?.folder_id}
     // folderName={data?.}
    />
   </Spin>
  </Modal>
 )
}

export default EmailTemplateModal
