/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { UploadFile, Tooltip } from 'antd'
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { utils } from 'utils'

const EmailAttachmentsPopver: FC<{
 onUpdate: (
  mode: 'attachment' | 'temp_attachment',
  v: UploadFile<any>[] | { path: string; size: number }[],
 ) => void
 attachments: UploadFile<any>[]
 tempAttachments: { path: string; size: number }[]
 isEditMode: boolean
}> = ({ onUpdate, attachments, tempAttachments, isEditMode = false }) => {
 const [openedFloatingMenu, setOpenedFloatingMenu] = useState(false)
 const allAttachments: any = [...attachments, ...tempAttachments]

 const ref = useRef<HTMLDivElement>(null)

 useClickAway(ref, () => {
  setOpenedFloatingMenu(false)
 })

 const file = (
  type: 'attachment' | 'temp_attachment',
  item: any,
  index: number,
 ): ReactNode => (
  <div key={index} className="attachment-item">
   <Tooltip
    overlay={
     //  isEditMode
     //   ? item?.file_name ?? item?.name
     //   : type === 'attachment'
     //   ? item?.name ?? item?.path
     //   : item?.file_name ?? item?.path
     item?.file_name ?? item?.name ?? item?.path
    }
   >
    <span className="line-clamp-1" style={{ width: '300px' }}>
     {/* {isEditMode
      ? item?.file_name ?? item?.name
      : type === 'attachment'
      ? item?.name ?? item?.path
      : item?.file_name ?? item?.path} */}
     {item?.file_name ?? item?.name ?? item?.path}
    </span>
   </Tooltip>
   <span className="ps-2" style={{ width: '75px', textAlign: 'center' }}>
    ({utils?.convertToMb(item?.size ?? 0)} MB)
   </span>{' '}
   <span
    className="ps-2"
    onClick={() => {
     onUpdate?.(
      type,
      type === 'attachment'
       ? isEditMode
         ? attachments?.filter(
            (i: any, k) => (i?.path ?? i?.uid) !== (item?.path ?? item?.uid),
           ) ?? []
         : attachments?.filter((i, n) => {
            if (i?.uid) {
             return i.uid !== item?.uid
            }
            return n !== index
           }) ?? []
       : tempAttachments?.filter((_i, k) => k !== index) ?? [],
     )
    }}
   >
    <i className="far fa-trash-alt" />
   </span>
  </div>
 )

 useEffect(() => {
  if ([...(attachments ?? []), ...(tempAttachments ?? [])]?.length < 2) {
   setOpenedFloatingMenu(false)
  }
 }, [attachments, tempAttachments])

 const firstAtt: any = useMemo(
  () => [...attachments, ...tempAttachments]?.[0],
  [[...attachments, ...tempAttachments]],
 )

 return (
  <div className="mail-attachments-sec" ref={ref}>
   <div className="mail-attachments-menu-item">
    <i className="far fa-paperclip" />
    <div
     className="d-flex name title"
     style={{
      justifyContent: 'space-between',
      ...(allAttachments?.length < 2 ? { width: '100%' } : {}),
     }}
    >
     <div style={{ display: 'flex', width: '100%' }}>
      <Tooltip
       overlay={
        // isEditMode
        //  ? allAttachments?.[0]?.file_name ?? allAttachments?.[0]?.name
        //  : allAttachments?.[0]?.file_name ??
        //    allAttachments?.[0]?.name ??
        //    allAttachments?.[0]?.path
        firstAtt?.file_name ?? firstAtt?.name ?? firstAtt?.path
       }
      >
       <span className="line-clamp-1" style={{ width: '100%' }}>
        {/* {isEditMode
         ? allAttachments?.[0]?.file_name ?? allAttachments?.[0]?.name
         : allAttachments?.[0]?.file_name ??
           allAttachments?.[0]?.name ??
           allAttachments?.[0]?.path} */}
        {firstAtt?.file_name ?? firstAtt?.name ?? firstAtt?.path}
       </span>
      </Tooltip>
      <span className="ps-2">
       ({utils?.convertToMb(allAttachments?.[0]?.size ?? 0)} MB)
      </span>{' '}
     </div>
     <div>
      <span
       className="ps-2"
       onClick={() => {
        onUpdate?.(
         attachments?.length ? 'attachment' : 'temp_attachment',
         attachments?.length
          ? attachments?.filter((i, k) =>
             isEditMode ? k !== 0 : i.uid !== attachments?.[0]?.uid,
            ) ?? []
          : tempAttachments?.filter((_i, k) => k !== 0) ?? [],
        )
       }}
      >
       <i className="far fa-trash-alt" />
      </span>
     </div>
    </div>
    {allAttachments?.length > 1 ? (
     <span
      className="name d-flex align-items-center"
      onClick={() => {
       setOpenedFloatingMenu(!openedFloatingMenu)
      }}
     >
      +{(allAttachments?.length ?? 0) - 1}{' '}
      <i
       className={`far fa-angle-${openedFloatingMenu ? 'up' : 'down'} ps-2`}
      />
     </span>
    ) : (
     ''
    )}
   </div>

   {openedFloatingMenu && (
    <div className="mail-attachments-list">
     {attachments?.length
      ? attachments?.slice(1)?.map((v, index) => file('attachment', v, index))
      : ''}
     {tempAttachments?.length ? (
      <div className="subtitle">Template Attachmentes</div>
     ) : (
      ''
     )}
     {tempAttachments?.length
      ? (attachments?.length
         ? tempAttachments
         : tempAttachments?.slice(1)
        )?.map((v, index) => file('temp_attachment', v, index))
      : ''}
    </div>
   )}
  </div>
 )
}

export default EmailAttachmentsPopver
