import { baseAPI, baseTransformResponse2 } from 'api/baseApi'
import { baseTransformResponse } from 'api/secondApi'

type TFolder = {
 body?: { name?: string; type: 'sms' | 'email' }
 method: string
 params?: string
}
type TFolderMove = {
 template_Ids?: number[]
 folder_id: number
 current_folder?: number
 type: 'sms' | 'email'
}

type TFolderCopy = {
 template_id: number
 folder_ids: number[]
 type: 'sms' | 'email'
}

export const templatesApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  updateTemplateFolder: mutation<void, TFolder>({
   query: ({ method, body, params = '' }) => ({
    url: `user/template-folders${params}`,
    method,
    ...(body ? { body: { ...body } } : {}),
   }),
   transformResponse: baseTransformResponse2,
  }),
  templateFolderMove: mutation<void, TFolderMove>({
   query: (body) => ({
    url: 'user/template-folders-move',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  templateFolderCopy: mutation<void, TFolderCopy>({
   query: (body) => ({
    url: 'user/template-folders-copy',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
 }),
})

export const {
 useUpdateTemplateFolderMutation,
 useTemplateFolderCopyMutation,
 useTemplateFolderMoveMutation,
} = templatesApi
