import { Col, Empty, Form, Input, Modal, Row, Select, Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import EmailATUpload from 'components/shared/Common/EmailATUpload'
import EmailSubjectInput from 'components/shared/Common/EmailSubjectInput'
import EmailTextArea from 'components/shared/Common/EmailTextArea'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { InfinityNewType, TMode } from 'types'
import { utils } from 'utils'
import { globalToaster } from 'components/elements/global'
// import { TGetEmailTemplates } from 'features/templates/types'
// import { LoadMoreSelect } from 'components/elements/Form'
import useFetchPage2 from 'hooks/useFetchPage2'
import UpdateTemplateFolder from 'features/templates/components/UpdateTemplateFolder'
import { InfiniteData } from 'react-query'
import { queryClient } from 'lib/react-query'
import BaseSelector, {
 BaseSelectorOption,
} from 'components/elements/BaseSelector'
import TrimFirstSpaceInput from 'components/elements/Form/TrimInput'
import {
 useAddEmailTemplateMutation,
 useEditEmailTemplateMutation,
} from '../api'
import { TAddEmailTemplate, TGetEmailTemplateId } from '../types'
import { SaveAsTemplateModal } from './TextTemplateForm'

const EmailTemplateForm: FC<{
 editData?: TGetEmailTemplateId
 onSuccess?: (v?: any) => void
 mode?: TMode
 showFolderList?: boolean
 folderId?: number
 folderName?: string
 onCancel?: () => void
}> = ({
 onSuccess,
 editData,
 mode = 'ADD',
 //  folderData,
 //  showFolderList = false,
 folderId,
 folderName,
 onCancel,
}) => {
 const [showSaveAsModal, setShowAsModal] = useState(false)
 const [form] = Form.useForm<TAddEmailTemplate>()
 const [saveAsEmailTemplate, { isLoading: saLoading }] =
  useAddEmailTemplateMutation()
 const [addEmailTemplate, { isLoading: addLoading }] =
  useAddEmailTemplateMutation()
 const [editEmailTemplate, { isLoading: editLoading }] =
  useEditEmailTemplateMutation()

 const [showAddFolder, setShowAddFolder] = useState(false)

 const editAttachments = Form.useWatch('temp_attachment', form)

 const convertToFormData = useCallback(
  (values: TAddEmailTemplate): FormData => {
   const formData = new FormData()
   formData.append('name', `${values.name}`)
   formData.append('subject', `${values.subject}`)
   //  if (showFolderList) {
   formData.append('folder_id', `${(values?.folder_id as any)?.[0]?.id}`)
   //  } else {
   //   formData.append('folder_id', `${folderId}`)
   //  }
   if (values.body) {
    formData.append('body', `${values.body}`)
   }
   formData.append('subject', `${values.subject}`)

   //  if ((values?.temp_attachment?.length ?? 0) > 0) {
   values?.temp_attachment?.forEach?.((v, i) => {
    // formData.append(`temp_attachment[${i}]`, v as any)
    formData.append(`temp_attachment[${i}][size]`, `${v?.size}`)
    formData.append(`temp_attachment[${i}][path]`, v?.path)
   })
   //  }

   values.attachment?.forEach((v, i) => {
    // if (v.type === 'url') {
    //  exData.push(`${v.url}`)
    // } else {
    formData.append(`attachment[${i}]`, v.originFileObj as any)
    // }
   })
   //  exData &&
   //   exData.length > 0 &&
   //   formData.append('ex-attachment', `${exData.join(',')}`)

   return formData
  },
  [],
 )

 const handleSubmit = async (): Promise<void> => {
  const values = await form.validateFields()
  const formData = convertToFormData(values)
  // for (const pair of formData.entries()) {
  // }
  if (
   Number(
    utils.convertToMb(
     values?.attachment
      ?.map((i) => Number(i?.size ?? 0))
      ?.reduce((a, b) => a + b, 0) ?? 0,
    ),
   ) > 10
  ) {
   globalToaster({
    type: 'error',
    content:
     'File Size Exceeded! The size of attachments (single or together) should be less than 10 MB',
   })
  } else if (mode === 'EDIT' && editData) {
   await editEmailTemplate({ body: formData, id: editData.id })
    .unwrap()
    .then((res) => {
     form.resetFields()
     if (res) {
      onSuccess?.(res)
     } else {
      onSuccess?.()
     }
    })
   //  form.resetFields()
   //  onSuccess?.()
  } else {
   await addEmailTemplate(formData)
    .unwrap()
    .then((res) => {
     form.resetFields()
     if (res) {
      onSuccess?.(res)
     } else {
      onSuccess?.()
     }
    })
  }
 }

 const handleSubmitSaveAs = async (title: string) => {
  const values = await form.validateFields()
  const formData = convertToFormData({
   ...values,
   //  folder_id: (values?.folder_id as any)?.[0]?.id,
   name: title,
  })
  await saveAsEmailTemplate(formData)
   .unwrap()
   .then((res) => {
    if (res) {
     onSuccess?.(res)
    } else {
     onSuccess?.()
    }
    setShowAsModal(false)
   })
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    name: editData.name,
    subject: editData.subject,
    body: editData.body,
    temp_attachment: editData?.attachment,
    ...(editData?.folder_id && editData?.folder_name
     ? {
        folder_id: [
         { id: editData?.folder_id, title: editData?.folder_name },
        ] as any,
       }
     : {}),
    // attachment:
    //  editData.attachment && editData.attachment?.length > 0
    //   ? editData.attachment.map((v, i) => ({
    //      thumbUrl: v?.path,
    //      url: v,
    //      name: v?.path,
    //      uid: `tempId-${i}`,
    //      type: 'url',
    //      size: v?.size,
    //     }))
    //   : [],
   })
  }
 }, [
  mode,
  // form,
  editData,
 ])
 const [foldersSearchKey, setFolderSearchKey] = useState('')

 const { isLoading, hasMore, fetchMore, flatData } = useFetchPage2<{
  id: number
  name: string
 }>('folder-search', {
  method: 'post',
  // body: { search: foldersSearchKey },
  params: `type=email&search=${foldersSearchKey}`,
 })

 useEffect(() => {
  if (folderId && folderName) {
   form.setFieldValue('folder_id', [{ id: folderId, title: folderName }])
  }
 }, [folderId, folderName])

 return (
  <>
   <Form form={form} layout="vertical">
    <Row gutter={15}>
     <Col span={24}>
      <Form.Item
       name="name"
       label="Template Name"
       rules={[
        { required: true, message: 'Template Name is required' },
        { min: 3, message: 'Minimum 3 Characters required' },
       ]}
      >
       {/* <Input placeholder="Template Name" /> */}
       <TrimFirstSpaceInput placeholder="Template Name" />
      </Form.Item>
     </Col>
     <Col span={24}>
      <Row>
       <Col span={23}>
        <Form.Item
         name="folder_id"
         label="Folder Name"
         rules={[{ required: true, message: 'Please Choose a Folder' }]}
        >
         <TemplateFolderInput
          fetchMore={fetchMore}
          hasMore={hasMore}
          isLoading={isLoading}
          flatData={flatData}
          setSearchKey={setFolderSearchKey}
         />
        </Form.Item>
       </Col>
       <Col span={1} className="d-flex align-items-center justify-content-end">
        <BaseButton
         tooltipText="Add New Folder"
         onClick={() => setShowAddFolder(true)}
         buttonType="gamma"
         icon={<i className="far fa-plus" />}
         customClass="ms-2"
         style={{ marginTop: '5px' }}
        />
       </Col>
      </Row>
     </Col>

     <Col span={24}>
      <Form.Item
       name="subject"
       label="Subject"
       rules={[
        { required: true, message: 'Subject is required' },
        { min: 3, message: 'Subject must be at least 3 characters.' },
       ]}
      >
       <EmailSubjectInput />
      </Form.Item>
     </Col>

     <Col span={24}>
      <Form.Item name="body" label="Body">
       <EmailTextArea mergfield />
      </Form.Item>
     </Col>
     <Col>
      <Form.Item name="attachment">
       <EmailATUpload
        oldAttachments={editAttachments}
        onUpdateOldAttachments={(v) => {
         form.setFieldValue('temp_attachment', v)
        }}
       />
      </Form.Item>
      <Form.Item name="temp_attachment" noStyle>
       {null}
      </Form.Item>
     </Col>
     <Col span={24}>
      <Space className="w-100 d-flex justify-content-end">
       <BaseButton
        buttonStyle="outline"
        buttonType="danger"
        onClick={() => onCancel?.()}
       >
        Cancel
       </BaseButton>
       <BaseButton
        loading={mode === 'EDIT' ? editLoading : addLoading}
        onClick={handleSubmit}
        buttonType="primary"
       >
        {mode === 'EDIT' ? 'Update Email Template' : 'Save Email Template'}
       </BaseButton>
       {mode === 'EDIT' && (
        <BaseButton onClick={() => setShowAsModal(true)} buttonType="info">
         Save As
        </BaseButton>
       )}
      </Space>
     </Col>
    </Row>
   </Form>
   {/* <SaveAsModal
    Loading={saLoading}
    handleSubmitSaveAs={handleSubmitSaveAs}
    visible={showSaveAsModal}
    onCancel={() => setShowAsModal(false)}
   /> */}
   {showSaveAsModal && (
    <SaveAsTemplateModal
     Loading={saLoading}
     handleSubmitSaveAs={handleSubmitSaveAs}
     visible={showSaveAsModal}
     onCancel={() => setShowAsModal(false)}
    />
   )}
   {showAddFolder && (
    <UpdateTemplateFolder
     from="Email"
     mode="ADD"
     onCancel={() => setShowAddFolder(false)}
     onSuccess={(newData) => {
      const cache = queryClient.getQueryData<
       InfiniteData<
        InfinityNewType<{
         id: number
         name: string
        }>
       >
      >(['folder-search', '', `type=email&search=${foldersSearchKey}`])

      const updatedData: any = {
       ...cache,
       pages:
        cache?.pages.map((page, index) =>
         index === 0
          ? {
             ...page,
             data: [newData ?? [], ...(page?.data ?? [])],
            }
          : page,
        ) ?? [],
      }
      if (updatedData && updatedData?.pages) {
       queryClient.setQueryData<
        InfiniteData<
         InfinityNewType<{
          id: number
          name: string
         }>
        >
       >(
        ['folder-search', '', `type=email&search=${foldersSearchKey}`],
        (data) => updatedData ?? {},
       )
      }
      form.setFieldValue('folder_id', [
       { id: newData?.id, title: newData?.name },
      ])
     }}
    />
   )}
  </>
 )
}

export default EmailTemplateForm

export const TemplateFolderInput: FC<{
 //  visible: boolean
 //  onCancel: () => void
 value?: BaseSelectorOption[]
 //  handleOkay?: (v: BaseSelectorOption[]) => void
 onChange?: (v: BaseSelectorOption[]) => void
 isLoading: boolean
 hasMore?: boolean
 fetchMore: () => void
 flatData?: {
  id: number
  name: string
 }[]
 setSearchKey: React.Dispatch<React.SetStateAction<string>>
}> = ({
 //  onCancel,
 //  visible,
 //  selectedValue,
 //  handleOkay,
 value,
 fetchMore,
 hasMore,
 isLoading,
 setSearchKey,
 flatData,
 onChange,
}) => {
 //  const [searchKey, setSearchKey] = useState('')
 const [showModal, setShowModal] = useState(false)

 const [selected, setSelected] = useState<BaseSelectorOption[]>(value ?? [])
 const [selectedValue, setSelectedValue] = useState<BaseSelectorOption[]>(
  value ?? [],
 )
 useEffect(() => {
  if (value) {
   setSelectedValue(value)
   setSelected(value)
  }
 }, [value])

 //  if (!showModal) {
 //   return (
 //    <Input
 //     value={selected?.[0]?.title ?? ''}
 //     onFocus={() => setShowModal(true)}
 //    />
 //   )
 //  }

 return (
  <>
   <Input
    value={selectedValue?.[0]?.title ?? ''}
    onFocus={() => setShowModal(true)}
    placeholder="Choose Folder"
   />
   {showModal && (
    <Modal
     onCancel={() => setShowModal(false)}
     visible={showModal}
     title="Choose Folder"
     bodyStyle={{ padding: 0 }}
     onOk={() => {
      onChange?.(selected)
      setSelectedValue(selected)
      setShowModal(false)
      // onCancel?.()
     }}
    >
     {/* {!isLoading && flatData?.length === 0 ? (
      <Empty />
     ) : ( */}
     <BaseSelector
      type="single"
      isLoading={isLoading}
      onSearch={setSearchKey}
      options={flatData?.map((v) => ({ title: v?.name, id: v?.id }))}
      value={selected}
      searchPlaceholder="Choose Folder"
      onChange={(e) => setSelected(e)}
      fetchMore={fetchMore}
      hasMore={hasMore}
      showEmpty={!isLoading && flatData?.length === 0}
     />
     {/* )} */}
    </Modal>
   )}
  </>
 )
}

// const SaveAsModal: FC<{
//  Loading?: boolean
//  handleSubmitSaveAs?: (title: string) => void
//  visible?: boolean
//  onCancel?: () => void
// }> = ({ visible, onCancel, handleSubmitSaveAs, Loading }) => {
//  const [input, setInput] = useState('')
//  return (
//   <Modal
//    confirmLoading={Loading}
//    okButtonProps={{ disabled: !input }}
//    onOk={() => handleSubmitSaveAs?.(input)}
//    okText="Save As"
//    title="Save As"
//    onCancel={onCancel}
//    visible={visible}
//   >
//    <Input
//     value={input}
//     onChange={(e) => setInput(e.target.value)}
//     placeholder="Enter A New Template Name"
//    />
//   </Modal>
//  )
// }
