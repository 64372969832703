import { Button, Tooltip } from 'antd'
// import Upload, { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { BaseButton } from 'components/elements/BaseButton'
// import { globalToaster } from 'components/elements/global'
import React, { FC, useEffect, useState } from 'react'
// import { TFileExt } from 'types'
import { utils } from 'utils'
import SWAttachmentModal from '../SendWidget/_components/SWAttachmentModal'

// const supporttedFiles: TFileExt[] = [
//  'pdf',
//  'csv',
//  'txt',
//  'xls',
//  'jpg',
//  'jpeg',
//  'png',
//  'docx',
//  'doc',
// ]

const EmailATUpload: FC<{
 value?: UploadFile[]
 onChange?: (val?: any) => void
 oldAttachments?: { path: string; size: number }[]
 onUpdateOldAttachments?: (v: { path: string; size: number }[]) => void
}> = ({ onChange, value, oldAttachments, onUpdateOldAttachments }) => {
 //  const [nValue, setValue] = useState(value)

 //  useEffect(() => {
 //   let mouted = true
 //   if (mouted && value) {
 //    setValue(value)
 //   }
 //   return () => {
 //    mouted = false
 //   }
 //  }, [value])

 //  const beforeUpload = (file: RcFile) => {
 //   const ext = file.name.split('.').pop()
 //   if (!supporttedFiles.some((v) => v === ext)) {
 //    globalToaster({ content: `${file.name} is Not Supported`, type: 'error' })
 //    return false
 //   }
 //   return false
 //  }

 //  const beforeUpload = (file: RcFile) => {
 //   if (!checkIsSupportedFile(file, supporttedFiles)) {
 //    globalToaster({ content: `${file.name} is Not Supported`, type: 'error' })
 //    return false
 //   }
 //   return false
 //  }

 const itemRender = (
  //   originNode: React.ReactElement,
  file: UploadFile & { path?: string },
  mode: 'old' | 'new',
  //   fileList: Array<UploadFile<any>>,
  //   actions: {
  //    download: () => void
  //    preview: () => void
  //    remove: () => void
  //   },
  index: number,
  onRemove: () => void,
 ): React.ReactNode => {
  return (
   <div className="ant-upload-list-item">
    {/* {mode === 'new' && file?.thumbUrl && (
     <img
      src={file.thumbUrl}
      alt=""
      className="ant-upload-list-item-thumbnail"
     />
    )} */}
    <Tooltip overlay={(file as any)?.file_name ?? file?.name ?? file?.path}>
     <span className="file-name">
      {(file as any)?.file_name ?? file?.name ?? file?.path}
     </span>
    </Tooltip>
    <span className="file-size">{`(${utils?.convertToMb(
     file?.size ?? 0,
    )} MB)`}</span>
    <span className="ant-upload-list-item-card-actions">
     {/* <Button
      icon={<i className="fas fa-eye" />}
      size="small"
      onClick={() => actions.preview()}
     /> */}
     <Button
      icon={<i className="far fa-trash-alt" />}
      size="small"
      //   onClick={() => actions.remove()}
      onClick={() => {
       //
       onRemove()
      }}
     />
    </span>
   </div>
  )
 }

 const [showModal, setShowModal] = useState(false)

 return (
  <>
   {/* <Upload
    // listType="picture"
    // className="upload-list-inline"
    // fileList={nValue}
    // showUploadList={{
    //  showRemoveIcon: true,
    //  showPreviewIcon: true,
    // }}
    showUploadList={false}
    onChange={(e) => {
     onChange?.(Array.isArray(e) ? e : e.fileList)
    }}
    multiple
    beforeUpload={beforeUpload}
    // itemRender={itemRender}
   >
    <BaseButton buttonType="secondary">
     <i className="fas fa-upload me-1" /> Attachment
    </BaseButton>
   </Upload> */}

   <BaseButton buttonType="secondary" onClick={() => setShowModal(true)}>
    <i className="fas fa-upload me-1" /> Attachment
   </BaseButton>
   {showModal && (
    <SWAttachmentModal
     visible
     onCancel={() => setShowModal(false)}
     onChange={(v) => {
      onChange?.([...(v ?? []), ...(value ?? [])])
     }}
    />
   )}
   {/* {itemRender} */}
   <div className="upload-list-inline">
    <div className="ant-upload-list">
     {value?.map((v, i) =>
      itemRender(v, 'new', i, () => {
       onChange?.(value?.filter((_, k) => k !== i))
      }),
     )}
     {oldAttachments?.map((v: any, i) =>
      itemRender(v, 'old', i, () => {
       onUpdateOldAttachments?.(oldAttachments?.filter((_, k) => k !== i))
      }),
     )}
    </div>
   </div>
  </>
 )
}

export default EmailATUpload
